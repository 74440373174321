import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {SearchOutlined} from '@ant-design/icons';
import {
  StyledSearch
} from './StyledSearch';
import {useDebounce} from '../../hooks';

const Search = ({
  defaultValue,
  delay,
  minSearchSymbolsLength,
  onChange,
  onSearch,
  value,
  ...rest
}) => {
  const [searchedValue, setSearchedValue] = useState(defaultValue || '');
  const [counterChange, setCounterChange] = useState(0);

  useDebounce(
    () => {
      if (counterChange > 0 && onSearch && (searchedValue === '' || searchedValue.length >= minSearchSymbolsLength)) onSearch(searchedValue);
      if (counterChange === 0) setCounterChange(1);
    },
    delay,
    [searchedValue]
  );

  useEffect(() => {
    setSearchedValue(value);
  }, [value]);

  useEffect(() => {
    defaultValue !== searchedValue && setSearchedValue(defaultValue || '');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = (e) => {
    onChange && onChange(e);
    setSearchedValue(e.target.value);
  }

  const handleOnSearchIconClick = () => onSearch && onSearch(searchedValue);

  return (
    <StyledSearch
      {...rest}
      defaultValue={defaultValue}
      suffix={<SearchOutlined onClick={handleOnSearchIconClick} />}
      onChange={handleOnChange}
    />
  );
}

Search.propTypes = {
  defaultValue: PropTypes.any,
  delay: PropTypes.number,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  minSearchSymbolsLength: PropTypes.number
}

Search.defaultProps = {
  delay: 1000,
  minSearchSymbolsLength: 3
}

export default Search;
