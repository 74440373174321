import {Form} from 'antd';
import {useTranslation} from 'react-i18next';
import {kycHelpers} from '../utils/helpers';

export const useTaxCodeLabel = (form) => {
  const [t] = useTranslation(['main', 'quickStart']);
  const taxResidence = Form.useWatch('tax_residence', {form});

  return kycHelpers.getTaxCodeLabelByCountryCode({t, taxResidence});
}

