import React from 'react';

const WarningIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9.48149 1.5931L14.3817 6.50216C14.775 6.89664 14.9959 7.43137 14.9959 7.98891C14.9959 8.54644 14.775 9.08117 14.3817 9.47565L9.48149 14.3847C9.08773 14.7787 8.55396 15 7.99744 15C7.44092 15 6.90715 14.7787 6.51338 14.3847L1.6132 9.47565C1.21992 9.08117 0.999023 8.54644 0.999023 7.98891C0.999023 7.43137 1.21992 6.89664 1.6132 6.50216L6.51338 1.5931C6.91399 1.21229 7.44519 1 7.99744 1C8.54969 1 9.08088 1.21229 9.48149 1.5931ZM7.13223 5.68412C7.13223 5.19387 7.52903 4.79634 8.0184 4.79634C8.50776 4.79634 8.90457 5.19387 8.90457 5.68412V7.78913C8.90457 8.27938 8.50776 8.67691 8.0184 8.67691C7.52903 8.67691 7.13223 8.27938 7.13223 7.78913V5.68412ZM7.13223 10.1329C7.13223 9.64261 7.52903 9.24509 8.0184 9.24509C8.50776 9.24509 8.90457 9.64261 8.90457 10.1329V10.3104C8.90457 10.8007 8.50776 11.1982 8.0184 11.1982C7.52903 11.1982 7.13223 10.8007 7.13223 10.3104V10.1329Z"
          fill="#000"/>
  </svg>
);

export default WarningIcon;
