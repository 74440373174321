import React from 'react';
import ls from 'localstorage-slim';
import {
  StyledCardPaymentTypeCardIconSpace
} from '../../components/pages/CardsPage/CardPaymentType/StyledCardPaymentType';
import {WithLessCreditCardIcon} from '../../icons';
import {
  cardBackendStatusesConstants as cardBackedStatus,
  cardStatusesConstants as cardStatus,
  KYCLevelStatusesConstants as kycLevelStatus,
  KYCReviewStatusesConstants as kycReviewStatus,
  cardVerificationLightStatusesConstants as lightStatus,
  cardLimitTypesList,
  cardLimitPeriodConstants,
  subscriptionFormFields,
  cardTypesConstants,
  cardDigitalizationStatusConstants,
  subscriptionBillTypesConstants
} from '../../constants';
import CardStatusMark from '../../components/pages/CardsPage/CardStatusMark';
import {fileHelpers} from './file.helpers';
import {OptionsList} from './form.helpers';
import {localizationHelpers} from './localization.helpers';
import {objectHelpers} from './object.helpers';

const {ACTIVE, FROZEN, PENDING, TERMINATED} = cardStatus;

const storedCardTableColumns = 'stored-cards-table-columns';

const cardTypeKeys = {
  [cardTypesConstants.FOR_PERSONAL_USE]: 'cardTypes.forPersonalUse',
  [cardTypesConstants.FOR_SUBSCRIPTION]: 'cardTypes.forSubscriptions',
};

const cardStatuses = {
  [cardBackedStatus.DESTROYED]: {
    key: 'cardStatuses.terminated',
    color: '#FF7875'
  },
  [cardBackedStatus.LOCK]: {
    key: 'cardStatuses.paused',
    color: '#FFCC00'
  },
  [cardBackedStatus.LOST]: {
    key: 'cardStatuses.terminated',
    color: '#FF7875'
  },
  [cardBackedStatus.UNLOCK]: {
    key: 'cardStatuses.active',
    color: '#52C41A'
  },
  [cardBackedStatus.STOLEN]: {
    key: 'cardStatuses.terminated',
    color: '#FF7875'
  },
  [cardBackedStatus.WAITING]: {
    key: 'cardStatuses.waiting',
    color: '#FFCC00'
  },
};

export const CardTypesOptions = (activeValue) => OptionsList(cardTypeKeys, activeValue);

export const cardsHelpers = {
  getStatus: (key, isLive, noId) => {
    const statuses = {
      [cardBackedStatus.DESTROYED]: TERMINATED,
      [cardBackedStatus.LOCK]: FROZEN,
      [cardBackedStatus.LOST]: TERMINATED,
      [cardBackedStatus.STOLEN]: TERMINATED,
      [cardBackedStatus.UNLOCK]: ACTIVE
    }
    // if ((!isLive && key === cardBackedStatus.UNLOCK) || (noId && key === null)) {
    if (noId && key === null) {
      return PENDING;
    }
    return statuses.hasOwnProperty(key) ? statuses[key] : TERMINATED;
  },
  getCardIcon: (card) => {
    let spaceClassName = card?.subscription_id ? 'for-subscription' : 'for-personal';
    return (
      <StyledCardPaymentTypeCardIconSpace
        className={spaceClassName}
      >
        {<WithLessCreditCardIcon />}
      </StyledCardPaymentTypeCardIconSpace>
    )
  },
  getCardStatus: ({statusCode}) => objectHelpers.getStatus(statusCode, cardStatuses),
  getCardStatusMark: (data) => (
    <CardStatusMark
      statusCode={data.lock_status}
    />
  ),
  getFormattedCardNumber: (value) => value.match(/.{1,4}/g).join(' '),
  getResponseErrors: (response) => {
    const errors = {};
    const data = response.detail;
    if (typeof data === 'object') {
      data.forEach(err => {
        let locLength = err.loc.length;
        if (err.type.startsWith('value_error') && locLength >= 2) {
          let fieldName = err.loc[locLength - 1];
          if (['legal_person', 'legal_representative', 'ultimate_beneficiary_owner'].includes(fieldName)) {
            fieldName = 'email';
          }
          errors[fieldName] = err.msg;
        }
      });
    }
    return errors;
  },
  getBankingResponseErrorFields: (response) => {
    const fields = [];
    const data = response.detail;
    if (typeof data === 'object') {
      data.forEach(err => {
        if ((err.type.startsWith('value_error') || err.type === 'missing') && err.loc.length >= 2) {
          fields.push({
            name: err.loc[err.loc.length - 1],
            errors: [err.msg]
          });
        }
      });
    }
    return fields;
  },
  getUploadedFilePayload: async (userId, document, documentTypeId) => {
    const fileContentBase64 = await fileHelpers.getBase64(document);
    let data = {
      document_type_id: documentTypeId,
      access_user_ip: '102.129.65.0',
      name: document.name,
      file_content_base64: fileContentBase64
    }
    if (userId) data = {...data, user_id: userId};
    return data;
  },
  getTaxResidenceFormData: (fields) => {
    const taxResidence = fields.taxResidence.toUpperCase();
    return {
      country: taxResidence,
      tax_payer_id: fields.tax_number
    };
  },
  getKYCStatus: (review, level) => {
    let status = lightStatus.NONE;
    if (review === kycReviewStatus.VALIDATED) {
      status = lightStatus.VALIDATED;
    } else if (level === kycLevelStatus.LIGHT) {
      const statuses = {
        [kycReviewStatus.PENDING]: lightStatus.PENDING,
        [kycReviewStatus.REFUSED]: lightStatus.REFUSED,
      }
      if (statuses.hasOwnProperty(review)) status = statuses[review];
    }
    return status;
  },
  checkIsAvailableBanking: (state) => state && (state.stage === 'card_stage' && state.state === 'card_editing'),
  formatCreditCardNumber: (text) => {
    let cardNumber;
    const masterCardStartNumber = '5';
    let splitNumber = text.split(' ');
    let startNumber = splitNumber[0];
    const startNumberLength = startNumber.length;
    if (startNumber.slice(0, 1) !== masterCardStartNumber || startNumberLength > 4) {
      startNumber = `${masterCardStartNumber}${startNumber.slice(startNumberLength - 3)}`;
    }
    cardNumber = [startNumber, ...splitNumber.splice(1)].join('');
    // mark value as undefined if value is recognized with uncorrected length
    cardNumber = cardNumber.length === 16 ? cardNumber.match(/.{1,4}/g).join(' ') : undefined;
    return cardNumber;
  },
  getCardDetailsFromImage: (image) => {
    return new Promise((resolve, reject) => {
      fileHelpers.recognizeImageToText(
        image,
        (result) => {
          try {
            // recognize card image
            let expired, cvv, owner, cardNumber;
            const resultText = result.data.text;
            // eslint-disable-next-line no-control-regex
            const separatedData = resultText.replace(/[^\x00-\x7F]/g, "").split(/\r?\n/).map(t => t.trim());
            const isOldTemplate = resultText.toLowerCase().indexOf('h1') >= 0;
            // recognize separate data start index
            const startIndex = isOldTemplate ? 0 : 1;

            const recognizeCvvData = (cvvData) => {
              // recognize cvv and expired values
              const cvvDataList = cvvData.split(' ');
              if (cvvDataList.length >= 3) {
                expired = cvvDataList[0];
                cvv = cvvData.slice(cvvDataList[0].length).replace(/\D+/g, "");
                // mark value as undefined if value is recognized with uncorrected length
                if (expired.length !== 5) expired = undefined;
                if (cvv.length !== 3) cvv = undefined;
              }
            }

            if (separatedData.length >= 5) {
              owner = separatedData[startIndex + 2].replaceAll('|', 'I');
              cardNumber = cardsHelpers.formatCreditCardNumber(separatedData[startIndex + 3]);
              recognizeCvvData(separatedData[startIndex + 4]);
            }

            // recognize cvv part of image if expired or cvv is recognized uncorrected
            if (expired === undefined || cvv === undefined) {
              const canvas = document.createElement('canvas'), // In memory canvas
                ctx = canvas.getContext('2d'),
                img = new Image();

              function handleLoadCvvDetails() {
                const width = img.width;
                const height = img.height;
                const sy = height * 0.7364;
                const sh = height - sy;
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(this, 0, sy, width, sh, 0, 0, width, sh);
                let base64Image = canvas.toDataURL(); // ("image/jpeg") for jpeg
                fileHelpers.recognizeImageToText(
                  base64Image,
                  (result) => {
                    try {
                      // eslint-disable-next-line no-control-regex
                      const separatedData = result.data.text.replace(/[^\x00-\x7F]/g, "").trim();
                      recognizeCvvData(separatedData);
                      resolve({
                        cvv,
                        expired,
                        number: cardNumber,
                        owner
                      });
                    } catch (e) {
                      reject(`An error occurred: ${e}`)
                    }
                  },
                  (err) => reject(err)
                )
              }

              img.onload = handleLoadCvvDetails;
              img.src = image;
            } else {
              resolve({
                cvv,
                expired,
                number: cardNumber,
                owner
              })
            }
          } catch (e) {
            reject(`An error occurred: ${e}`)
          }
        },
        (err) => reject(err)
      );
    })
  },
  getPaymentCardLimit: (state) => {
    const personalCardLimit = 5000;
    let subscriptionCardLimit = 10000;
    try {
      subscriptionCardLimit = Number(state.banking.companyDetails.limit_payment_card);
    } catch {}
    return {personalCardLimit, subscriptionCardLimit};
  },
  getCardLimit: (card = {}) => {
    let limitValue = 0;
    let limitName = '';
    let option = '';
    if (card) {
      cardLimitTypesList.forEach(key => {
        let value = card[key];
        if (typeof value === 'number' && value > 0) {
          limitValue = value;
          limitName = key;
        }
      });
      option = Object.values(cardLimitPeriodConstants).find(key => limitName.split('_').includes(key));
    }
    return {
      option,
      name: limitName,
      value: limitValue
    }
  },
  getCardTotalPaymentValue: (card = {}) => {
    let value = 0;
    let name = '';
    if (card) {
      cardLimitTypesList.forEach(key => {
        let value = card[key];
        if (typeof value === 'number' && value > 0) name = key;
      });
      if (name) {
        name = name.replace('limit', 'total');
        value = card[name] || 0;
      }
    }
    return value;
  },
  getSetLimitPayload: (formData) => {
    let data = {};
    let period = formData[subscriptionFormFields.cardLimitPeriodFieldName];
    let limit = formData[subscriptionFormFields.cardLimitFieldName];
    cardLimitTypesList.forEach(limitKey => {
      data = {...data, [limitKey]: limitKey.split('_').includes(period) ? limit : 0};
    })
    return data;
  },
  getBankingCompanyDetails: (state) => {
    const getProp = (propName) => objectHelpers.getObjProp(state.banking.legalPerson, propName, '') || '';

    const countryCode = getProp('country');
    const countryObj = localizationHelpers.getCountryByCode(countryCode);
    const countryName = countryObj?.name || countryCode;
    const city = getProp('city');
    const postCode = getProp('postcode');
    const address = `${getProp('address1')}, ${postCode} ${city}, ${countryName}`;
    const vat = state?.banking?.legalPerson?.legal_tva_number || '';

    return {
      address,
      city,
      country: countryName,
      name: getProp('legal_name'),
      vat
    }
  },
  getStoredCardsTableColumns: () => ls.get(storedCardTableColumns),
  storeCardsTableColumns: (value) => ls.set(storedCardTableColumns, value),
  getDefaultCardsTableColumns: (defaultKeys) => cardsHelpers.getStoredCardsTableColumns() || defaultKeys,
  getAvailableCards: (cards = []) => cards.filter(card => [cardBackedStatus.LOCK, cardBackedStatus.UNLOCK].includes(card.status_code)),
  isDigitalizedCard: (card) => objectHelpers.getObjProp(card, 'digitalization_status') === cardDigitalizationStatusConstants.ACTIVE,
  isTooHighLimit: ({limit = 0, maxLimit, period}) => {
    if (period === cardLimitPeriodConstants.YEAR) {
      maxLimit = maxLimit * 12;
    }
    return limit > maxLimit;
  },
  getLast4Digits: (value) => {
    const len = value ? value.length : 0;
    return value ? value.substring(len - 4, len) : 'N.A.';
  },
  hideCardNumber: (cardNumber, symbolsLength = 8) => {
    if (cardNumber) {
      const len = cardNumber.length;
      return cardNumber ? cardNumber.substring(len - symbolsLength, len) : '';
    } else {
      return ''
    }
  },
  getCardLimitTypeFromBilled: (billed) => ({
    [subscriptionBillTypesConstants.PASS_AS_YOU_GO]: cardLimitPeriodConstants.ALL,
    [subscriptionBillTypesConstants.MONTHLY]: cardLimitPeriodConstants.MONTH,
    [subscriptionBillTypesConstants.YEARLY]: cardLimitPeriodConstants.YEAR
  })[billed] || undefined,
}
