import { useState, useEffect } from 'react';
import useGeoLocation from 'react-ipgeolocation';
import {localizationHelpers} from '../utils/helpers';

export const useLocation = () => {
  const [country, setCountry] = useState({code: undefined, name: undefined, phone_code: undefined});
  const [isLocated, setIsLocated] = useState(false);
  const location = useGeoLocation();

  const getCountry = (countryCode) => {
    const country = localizationHelpers.getCountryByCode(countryCode);
    if (country) {
      setCountry({
        code: country['ISO3166-1-Alpha-2'],
        name: country.name,
        phone_code: country.country_code
      });
    }
  }

  useEffect(() => {
    const {country} = location;
    if (country && !isLocated) {
      getCountry(country);
      setIsLocated(true);
    }
  }, [location, isLocated]); // eslint-disable-line react-hooks/exhaustive-deps

  return country;
}

