const defaultStatus = {
  key: null,
  color: '#ededed'
};

export const objectHelpers = {
  arraysIsEqual: (arr1, arr2) => {
    try {
      return JSON.stringify(arr1) === JSON.stringify(arr2)
    } catch (e) {
      return false
    }
  },
  getObjProp: (obj, propName, defaultValue = '') => {
    if (obj && obj.hasOwnProperty(propName)) {
      return obj[propName]
    } else {
      return defaultValue
    }
  },
  getStatus: (statusKey, statusType) => statusType.hasOwnProperty(statusKey) ? statusType[statusKey] : defaultStatus,
  isEmptyObject: (obj) => objectHelpers.arraysIsEqual(obj, {}),
  isEmptyQuery: (query) => query ? !Boolean(Object.keys(query).filter(key => query[key] && query[key].length).length) : true,
  isNaV: (value) => [null, undefined].includes(value),
  isValidValue: (value) => value !== undefined && value !== null && value !== '',
  range: (start, end) => (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start),
  trimAllValues: (fields = {}) => {
    return Object.keys(fields).reduce((prev, next) => Object.assign(prev, {
      [next]: fields[next] ? fields[next].trim() : fields[next]
    }), {});
  },
}
