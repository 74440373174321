import React from 'react';
import {CircleFlag} from 'react-circle-flags';
import supportedCountries from '../../i18n/supported-countries.json';
import taxCodesByCountry from '../../i18n/tax_codes_by_country.json';
import {objectHelpers} from './object.helpers';


export const localizationHelpers = {
  getSupportedCountries: () => supportedCountries.sort((a, b) => a.name.localeCompare(b.name)),
  getCountryByCode: (code, useDefault = false, defaultKey = 'it') => {
    let country = null;
    let activeCountry = supportedCountries.filter(c => c['ISO3166-1-Alpha-2'].toLowerCase() === code.toLowerCase());
    if (activeCountry.length > 0) {
      country = activeCountry[0]
    } else {
      if (useDefault) {
        let activeCountry = supportedCountries.filter(c => c['ISO3166-1-Alpha-2'].toLowerCase() === defaultKey.toLowerCase());
        if (activeCountry.length > 0) country = activeCountry[0];
      }
    }
    return country;
  },
  getCountryCodeByName: (name) => {
    if (typeof name === 'string') name = name.toLowerCase();
    let activeCountry = supportedCountries.find(c => c.name.toLowerCase() === name);
    return activeCountry ? activeCountry['ISO3166-1-Alpha-2'].toLowerCase() : '';
  },
  getCountryName: (countryCode) => {
    let country = localizationHelpers.getCountryByCode(countryCode);
    return country ? country.name : countryCode
  },
  getCountryOptions: (propName = 'ISO3166-1-Alpha-2') => {
    const countries = localizationHelpers.getSupportedCountries();
    return countries.map(c => {
      let flagCode = '';
      let value = '';

      try {
        value = c[propName];
        flagCode = c['ISO3166-1-Alpha-2'];
      } catch (e) {
        const alphaThreeCode = c['ISO3166-1-Alpha-3'];
        if (alphaThreeCode) {
          flagCode = value = alphaThreeCode;
        }
      }

      return {
        label: c.name,
        value: value,
        image: (
          <CircleFlag
            countryCode={flagCode.toLowerCase()}
            height={22}
          />
        )
      }
    });
  },
  getTaxCodeByCountry: (countryCode) => countryCode ? objectHelpers.getObjProp(taxCodesByCountry, countryCode.toUpperCase()) : '',
}
