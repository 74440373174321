import { useState, useEffect } from 'react';

const getWindowDimensions = (blockedWidth, blockedHeight) => {
  const { innerWidth: width, innerHeight: height } = window;
  const isBlockedWidth = blockedWidth > width || false;
  const isBlockedHeight = blockedHeight > height || false;
  return {
    width,
    height,
    isBlocked: isBlockedWidth || isBlockedHeight
  }
};

export const useWindowExtensionBlock = (blockedWidth, blockedHeight) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(blockedWidth, blockedHeight));

  useEffect(() => {
    function handleResize() {
      const {isBlocked} = getWindowDimensions(blockedWidth, blockedHeight);
      if (isBlocked !== windowDimensions.isBlocked) {
        setWindowDimensions(getWindowDimensions(blockedWidth, blockedHeight));
      }
    }
    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [blockedWidth, blockedHeight, windowDimensions]);

  return windowDimensions;
}
