const localStorageKeysConstants = Object.freeze({
  CLOSED_BALANCE_AUTH: 'closed_balance_auth',
  COMPANY_ID: 'company',
  PHONE_NUMBER: 'f7a42fe7211f98ac7a60a285ac3a9e87',
  SESSION_ID: 'session_id',
  SHOW_WELCOME: 'showWelcome',
  TOKEN: 'token',
  TWO_FA_STARTED: 'c035cae691c14072bcbe1053538a9ad9',
  SCA_HEADER_TYPE: 'headerType',
  SCA_SESSION_KEY: 'session_key'
});
export {localStorageKeysConstants};
