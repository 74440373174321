import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Skeleton} from 'antd';
import {userActions} from '../../../../../state/actions';
import {useContentLoaded} from '../../../../../hooks';
import {StyledNotifications, StyledSwitch, StyledToggle} from './StyledNotifications';
import UserInfo from '../../tabComponents/UserInfo';
import {elementHelpers, objectHelpers, systemHelpers} from '../../../../../utils/helpers';
import {firebaseEvents} from '../../../../../snippets/firebase';

const gOP = objectHelpers.getObjProp;

const Notifications = ({
  dispatch,
  settings,
  getSettings,
  updateEmployeeEmailNotifications,
  ...rest
}) => {
  const [trans] = useTranslation(['profile', 'main']);
  const isLoaded = useContentLoaded(settings);
  const [formState, setFormState] = useState({
    enableEmailNotifications: false,
  });
  const [email, setEmail] = useState({
    week: true,
    month: false
  });

  const t = (key) => trans(`tabs.notifications.${key}`);

  useEffect(() => getSettings(), [getSettings]);

  useEffect(() => {
    if (!objectHelpers.isEmptyObject(settings)) {
      const getObjProp = (propName) => gOP(settings, propName, false);
      const onceWeek = gOP(settings, 'email_notifications_regularity', 0) === 0;

      setFormState({
        enableEmailNotifications: getObjProp('enable_email_notifications'),
      });
      setEmail({
        week: onceWeek,
        month: !onceWeek
      });
    }
  }, [settings]);

  const onToggleChange = (e, stateName, path) => {
    const value = !formState[stateName];
    let data;
    setFormState({...formState, [stateName]: value});
    switch (path) {
      case 'email':
        data = {
          enable_email_notifications: value,
          email_notifications_regularity: !email.week
        };
        systemHelpers.logEvent(value ? firebaseEvents.PROFILE_SETTINGS_ENABLE_EMAIL_NOTIFICATION : firebaseEvents.PROFILE_SETTINGS_DISABLE_EMAIL_NOTIFICATION);
        break;
      default:
        break;
    }
    updateEmployeeEmailNotifications(data);
  }
  const showContent = (content, loader) => elementHelpers.showContentWithLoader(isLoaded, content, loader);

  const showToggle = (stateName, path) => (
    <div data-testid={stateName}>
      {showContent(
        <StyledSwitch
          checked={formState[stateName]}
          onChange={(e) => onToggleChange(e, stateName, path)}
        />,
        <Skeleton.Button active />
      )}
    </div>
  );

  const toggles = [
    {
      description: t('receivedThroughYouEmail'),
      key: 'email',
      title: trans('main:email'),
      toggle: showToggle('enableEmailNotifications', 'email')
    }
  ];

  return (
    <StyledNotifications
      title={<UserInfo />}
      {...rest}
    >
      {toggles.map(t => (
        <StyledToggle key={t.key}>
          <div>
            <h3>{t.title}</h3>
            <p>{t.description}</p>
          </div>
          {t.toggle}
        </StyledToggle>
      ))}
    </StyledNotifications>
  );
}


const mapStateToProps = state => {
  let {notificationSettings} = state.user;

  return {
    settings: notificationSettings
  }
}

const mapDispatchToProps = {
  getSettings: userActions.getEmployeeNotificationSettings,
  updateEmployeeEmailNotifications: userActions.updateEmployeeEmailNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
