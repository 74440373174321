import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, DatePicker, Form, Input, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {StyledLightKYCModal} from './StyledLightKYCModal';
import {StyledKYCModalTitleNameSpace} from '../KYCModal/StyledKYCModal';
import SpinSmall from '../../SpinSmall';
import AntdCountriesSelect from '../../Select/AntdCountriesSelect';
import PhoneNumberInput from '../../PhoneNumberInput';
import {useLocation, useTaxCodeLabel} from '../../../hooks';
import {bankingActions, mainActions} from '../../../state/actions';
import {bankingUserTypesConstants} from '../../../constants';
import {kycHelpers, objectHelpers, textHelpers} from '../../../utils/helpers';
import {titleOptions} from '../../../utils/options';

const {Item} = Form;

const countrySelectProps = kycHelpers.getCountrySelectProps();
const datePickerProps = kycHelpers.getDatePickerProps('birthday');

const initialFormValues = {
  birthday: undefined,
  birth_country: undefined,
  firstname: '',
  email: '',
  lastname: '',
  nationality: undefined,
  phone: '',
  place_of_birth: '',
  tax_residence: undefined,
  tax_number: '',
  title: titleOptions[0].value
}

const LightKYCModal = ({
  dispatch,
  employee,
  employeeId,
  isAdmin,
  legalPerson,
  open,

  closeLightKycWindow,
  createUser,
  createTaxResidence,
  getUserCardList,
  getCardsList,
  updateCardVerificationStatus,
  updateUser,
  onCancel,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'quickStart']);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [form] = Form.useForm();
  const {phone_code: defaultPhoneCode, code: defaultCountryCode} = useLocation();
  const taxCodeLabel = useTaxCodeLabel(form);

  useEffect(() => {
    if (defaultCountryCode) {
      form.setFieldsValue({
        birth_country: defaultCountryCode,
        country: defaultCountryCode,
        nationality: defaultCountryCode
      });
    }
  }, [defaultCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const gObjProp = (key) => objectHelpers.getObjProp(employee, key);
    if (employee) {
      const {firstName, lastName} = textHelpers.getNameParts(gObjProp('full_name'));
      form.setFieldsValue({
        firstname: firstName,
        lastname: lastName,
        email: gObjProp('email'),
        phone: gObjProp('phone_number')
      });
    }
  }, [employee, form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // clear user id if employee has been changed
    if (employeeId && userId === null) setUserId(null);
  }, [employeeId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = (...rest) => {
    closeLightKycWindow();
    onCancel && onCancel(...rest);
  }

  const {
    label,
    lengthFieldMessage,
    title,
    placeholder,
    validationPhoneRules,
    validationRequiredRules,
    validationTaxRules,
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, fullFormKey: 'quickStart:lightKyc'}), [t]);

  const footer = (
    <Button
      disabled={loading}
      onClick={() => form.submit()}
      size='large'
      type='primary'
    >
      {t('add')}
    </Button>
  );

  const onFinish = (fields) => {
    const data = {
      ...fields,
      ...kycHelpers.getUserCompanyFields(legalPerson),
      birthday: fields.birthday.format('YYYY-MM-DD'),
      email: fields.email.trim(),
      firstname: fields.firstname.trim(),
      lastname: fields.lastname.trim(),
      phone: fields.phone.trim(),
      // additional params
      tos_accepted: true, // I certify that I am only an Italian fiscal resident
      user_type: bankingUserTypesConstants.EMPLOYEE,
      employee_id: employeeId,
    };
    setLoading(true);

    const successCallback = (userId) => {
      createTaxResidence(
        {
          ...kycHelpers.getTaxResidenceFormData(data),
          user_id: userId
        },
        () => {
          // Update cards module after 3 second (the backend should be ready)
          setTimeout(() => isAdmin ? getCardsList() : getUserCardList(employeeId), 3000);
          updateCardVerificationStatus();
          setLoading(false);
          handleClose();
        },
        (response) => {
          const formErrors = kycHelpers.getTaxNumberFormErrors(response);
          errorCallback(formErrors);
        }
      );
    }

    const errorCallback = (formErrors) => {
      form.setFields(formErrors);
      setLoading(false);
    }

    const errorUserCallback = (response) => {
      let formErrors = kycHelpers.getUserCreateFormErrors(response, t);
      errorCallback(formErrors);
    }

    if (userId) {
      updateUser(
        userId,
        data,
        () => successCallback(userId),
        errorUserCallback
      );
    } else {
      createUser(
        data,
        (user) => {
          const userId = user.id;
          setUserId(userId);
          successCallback(userId);
        },
        errorUserCallback
      );
    }
  }

  return (
    <StyledLightKYCModal
      forceRender
      footer={footer}
      onClose={handleClose}
      open={open}
      title={title}
      width={472}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          form={form}
          layout='vertical'
          onFinish={onFinish}
          requiredMark={false}
        >
          <StyledKYCModalTitleNameSpace size={16} align='start'>
            <Item
              label={label('title')}
              name='title'
              required
            >
              <Select
                options={titleOptions}
                size='large'
              />
            </Item>
            <Item
              label={label('firstName')}
              name='firstname'
              rules={[
                ...validationRequiredRules,
                {min: 2, message: lengthFieldMessage(2, 'min')}
              ]}
            >
              <Input
                minLength={2}
                maxLength={50}
                placeholder={placeholder('firstName')}
                size='large'
              />
            </Item>
          </StyledKYCModalTitleNameSpace>
          <Item
            label={label('lastName')}
            name='lastname'
            rules={[
              ...validationRequiredRules,
              {min: 2, message: lengthFieldMessage(2, 'min')}
            ]}
          >
            <Input
              minLength={2}
              maxLength={50}
              placeholder={placeholder('lastName')}
              size='large'
            />
          </Item>
          <Item
            label={label('email')}
            name='email'
            rules={validationRequiredRules}
          >
            <Input
              placeholder={placeholder('email')}
              size='large'
              type='email'
            />
          </Item>
          <Item
            label={label('phoneNumber')}
            name='phone'
            rules={validationPhoneRules}
          >
            <PhoneNumberInput
              placeholder={placeholder('phoneNumber')}
              phoneCode={defaultPhoneCode}
              size='large'
            />
          </Item>
          <Item
            label={label('dateOfBirth')}
            name='birthday'
            rules={validationRequiredRules}
          >
            <DatePicker
              {...datePickerProps}
              placeholder={placeholder('dateOfBirth')}
            />
          </Item>
          <Item
            label={label('placeOfBirth')}
            name='place_of_birth'
            rules={validationRequiredRules}
          >
            <Input
              placeholder={placeholder('placeOfBirth')}
              size='large'
            />
          </Item>

          <Item
            label={label('countryOfBirth')}
            name='birth_country'
            rules={validationRequiredRules}
          >
            <AntdCountriesSelect
              placeholder={placeholder('countryOfBirth')}
              {...countrySelectProps}
            />
          </Item>
          <Item
            label={label('nationality')}
            name='nationality'
            rules={validationRequiredRules}
          >
            <AntdCountriesSelect
              placeholder={placeholder('nationality')}
              {...countrySelectProps}
            />
          </Item>
          <Item
            label={label('taxResidence')}
            name='tax_residence'
            rules={validationRequiredRules}
          >
            <AntdCountriesSelect
              placeholder={placeholder('taxResidence')}
              {...countrySelectProps}
            />
          </Item>
          <Item
            label={taxCodeLabel}
            name='tax_number'
            rules={validationTaxRules}
          >
            <Input
              placeholder={placeholder('taxCode')}
              size='large'
            />
          </Item>
        </Form>
      </SpinSmall>
    </StyledLightKYCModal>
  );
}

LightKYCModal.propTypes = {
  onCancel: PropTypes.func,
  open: PropTypes.bool
}

LightKYCModal.defaultProps = {
  open: false
}

const mapStateToProps = state => {
  const {open} = state.main.lightKycWindowProps;
  const {legalPerson} = state.banking;
  const {isAdmin, user} = state.user;
  const employee = {...state.user.employee, phone_number: user?.phone_number}
  const {id: employeeId} = employee;

  return {
    employee,
    employeeId,
    isAdmin,
    legalPerson,
    open,
  }
}

const mapDispatchToProps = {
  closeLightKycWindow: mainActions.closeLightKycWindow,
  createTaxResidence: bankingActions.createTaxResidence,
  createUser: bankingActions.createUser,
  updateUser: bankingActions.updateUser,
  getUserCardList: bankingActions.getUserCardList,
  getCardsList: bankingActions.getCardsList,
  updateCardVerificationStatus: bankingActions.updateCardVerificationStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(LightKYCModal);

