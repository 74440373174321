import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {StyledUserRoleSelectContainer, StyledUserRoleSelect} from './StyledUserRoleSelect';
import {userRolesConstants} from '../../../../../constants';
import {teamsHelpers} from '../../../../../utils/helpers';

const {ADMIN, BOOKKEEPER, SUPPORT, USER} = userRolesConstants;

const UserRoleSelect = ({
  dispatch,
  defaultValue,
  email,
  editorEmail,
  onChange,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [value, setValue] = useState(defaultValue || USER);

  const options = useMemo(() => {
    let options = [
      {label: t('userRoles.admin'), value: ADMIN},
      {label: t('userRoles.user'), value: USER},
      {label: t('userRoles.bookkeeper'), value: BOOKKEEPER},
      {label: t('userRoles.support'), value: SUPPORT},
    ];
    const isSupportRole = teamsHelpers.isSupport(defaultValue);
    if (!isSupportRole) options = options.filter(o => !teamsHelpers.isSupport(o.value));
    return options;
  }, [defaultValue, t]);

  const hideSelect = useMemo(() => {
    const isEditor = editorEmail === email;
    const isSupportRole = teamsHelpers.isSupport(defaultValue);
    return isEditor || isSupportRole;
  }, [defaultValue, editorEmail, email]);

  const handleOnChange = (value) => {
    setValue(value);
    onChange && onChange(value);
  };

  const option = options.find(o => o.value === value);
  const optionLabel = option?.label || '';

  return (
    <StyledUserRoleSelectContainer {...rest}>
      {
        hideSelect
          ? optionLabel
          : (
            <StyledUserRoleSelect
              bordered={false}
              onChange={handleOnChange}
              options={options}
              value={value}
            />
          )
      }
    </StyledUserRoleSelectContainer>
  );
}

UserRoleSelect.propTypes = {
  onChange: PropTypes.func,
}

const mapStateToProps = state => {
  const {employee} = state.user;
  return {
    editorEmail: employee.email,
  }
}

export default connect(mapStateToProps, null)(UserRoleSelect);
