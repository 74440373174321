import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Modal} from 'antd';
import moment from 'moment/moment';
import SubscriptionDetails from '../../tabComponents/Overview/SubscriptionDetails';
import PaymentDetails from '../../tabComponents/Overview/PaymentDetails';
import AuthenticationWindow from '../../../CardsPage/AuthenticationWindow';
import {alertActions, bankingActions, subscriptionActions} from '../../../../../state/actions';
import {
  subscriptionPaymentTypesConstants,
  SCAActionsConstants,
  subscriptionActionConstants,
  subscriptionFormFields,
  subscriptionFormValues
} from '../../../../../constants';
import TransactionHeaderDetails
  from '../../tabComponents/Transactions/TransactionHeaderDetails/TransactionHeaderDetails';
import {
  StyledOverviewTabContainer,
  StyledDetailsContainer,
  StyledDetailsContent
} from './StyledOverviewTab';
import SpinSmall from '../../../../SpinSmall';
import BudgetDetails from '../../tabComponents/Overview/BudgetDetails';
import ContractDetails from '../../tabComponents/Overview/ContractDetails';
import HistoryDetails from '../../tabComponents/Overview/HistoryDetails';
import AddCardModal from '../../tabComponents/Overview/AddCardModal';
import ChangeMethodModal from '../../tabComponents/Overview/ChangeMethodModal';
import {
  cardsHelpers,
  dateHelpers,
  objectHelpers,
  scaHelpers,
  subscriptionsHelpers,
  systemHelpers,
} from '../../../../../utils/helpers';
import {firebaseEvents} from '../../../../../snippets/firebase';

const {CARD, NOT_APPLICABLE} = subscriptionPaymentTypesConstants;

const {logEvent} = systemHelpers;
const {getObjProp: gObjProp} = objectHelpers;

const {
  SUBSCRIPTION_CARD_CREATION_ACTION,
  CARD_EDITION_ACTION,
  SUBSCRIPTION_CARD_RECREATE_ACTION,
  SUBSCRIPTION_CHANGE_PAYMENT_METHOD_ACTION,
  SUBSCRIPTION_EDIT_ACTION,
  LOCK_CARD_ACTION,
  UNLOCK_CARD_ACTION
} = SCAActionsConstants;

const defaultModalProps = {loading: false, open: false};

const OverviewTab = ({
  subscription,
  card,
  edit,
  updateSubscription,
  updateCardLimits,
  handleUpdateData,
  subscriptionQuickAction,
  transactionsCount
}) => {
  const [t] = useTranslation(['subscriptions', 'main']);
  const [isLoadingBudgetDetails, setIsLoadingBudgetDetails] = useState(false);
  const [isLoadingContactDetails, setIsLoadingContactDetails] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoadingPaymentDetails, setIsLoadingPaymentDetails] = useState(false);
  const [cardDetails, setCardDetails] = useState({loading: false, data: null, processing: false});
  const [addCardModalProps, setAddCardModalProps] = useState(defaultModalProps);
  const [changeMethodModalProps, setChangeMethodModalProps] = useState(defaultModalProps);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [tempData, setTempData] = useState(undefined);
  const [activeAction, setActiveAction] = useState(null);

  const paymentType = gObjProp(subscription, 'payment_type');
  const isCardPaymentType = paymentType === CARD;

  useEffect(() => card && setCardDetails({...cardDetails, data: card, loading: false}), [card]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAuthHeaders = (action) => scaHelpers.getAuthHeaders(action || activeAction);

  const SCAErrorCallback = ({response, successCallback, errorCallback}) => {
    scaHelpers.SCAResponseCallback({
      response,
      scaCallback: (scaProps) => {
        setAuthWindowProps({...authWindowProps, ...scaProps});
        successCallback && successCallback();
      },
      errorCallback
    });
  }

  const handleSubmitContractDetails = (data, successCallback, errorCallback) => {
    setIsLoadingContactDetails(true);
    updateSubscription({
      id: subscription.id,
      data,
      successCallback: (resp) => {
        finishSubscriptionUpdate(false, false, false);
        setIsLoadingContactDetails(false);
        successCallback && successCallback(resp);
      },
      errorCallback: (resp) => {
        const {data} = resp;
        let errorsList = {};
        setIsLoadingContactDetails(false);
        if (data.hasOwnProperty('errors')) {
          Object.keys(data.errors).forEach(key => {
            errorsList[key] = data.errors[key];
          });
        }
        errorCallback && errorCallback(errorsList);
      }
    });
  }

  const handleSubmitPaymentDetails = (data) => {
    setActiveAction(CARD_EDITION_ACTION);
    setTempData(data);
    handleUpdatePaymentDetails({action: CARD_EDITION_ACTION, data});
  };

  const handleSubmitSubscriptionDetails = (data) => {
    setActiveAction(SUBSCRIPTION_EDIT_ACTION);
    setTempData(data);
    handleUpdateSubscription(data);
  }

  const handleCreateCard = ({action, data} = {}) => {
    const subscriptionData = data || tempData;
    setAddCardModalProps({...addCardModalProps, loading: true});
    updateSubscription({
      id: subscription.id,
      data: {
        ...subscriptionData,
        // send current billed value (required in api)
        billed: subscription.billed || subscriptionFormValues.defaultBilled
      },
      headers: getAuthHeaders(action || activeAction),
      successCallback: () => {
        finishSubscriptionUpdate(true, false, true);
        logEvent(firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_ADD_CARD);
        handleCancelAddCardModal();
      },
      errorCallback: (response) => {
        SCAErrorCallback({
          response,
          errorCallback: () => setAddCardModalProps({...addCardModalProps, loading: false})
        });
      }
    });
  }

  const handleUpdatePaymentMethod = ({action, data, isRequiredCardCreation = false}) => {
    if (isRequiredCardCreation) {
      setChangeMethodModalProps({...changeMethodModalProps, loading: true});
    } else {
      setIsLoadingPaymentDetails(true);
    }

    // step 1: Update subscription details
    updateSubscription({
      id: subscription.id,
      data,
      headers: getAuthHeaders(action || activeAction),
      successCallback: () => {
        finishSubscriptionUpdate(true, false, true);
        if (isRequiredCardCreation) {
          logEvent(firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_CHANGE_PAYMENT_METHOD);
          handleCancelChangeMethodModal();
        } else {
          logEvent(firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_EDIT_PAYMENT_METHOD);
          finishDetailsWindowLoading();
        }
      },
      errorCallback: (response) => {
        SCAErrorCallback({
          response,
          errorCallback: () => {
            isRequiredCardCreation
              ? setChangeMethodModalProps({...changeMethodModalProps, loading: false})
              : finishPaymentDetailsLoading();
          }
        });
      }
    });
  }

  const handleUpdatePaymentDetails = ({action, data} = {}) => {
    const formData = data || tempData;
    setIsLoadingPaymentDetails(true);

    const handleUpdateCardLimits = (subscription, isUpdatedSubscription = false) => {
      // update card limit if limit is changed
      if (
        formData.hasOwnProperty(subscriptionFormFields.cardLimitFieldName) ||
        formData.hasOwnProperty(subscriptionFormFields.cardLimitPeriodFieldName)
      ) {
        const cardOwnerId = gObjProp(subscription.card_owner, 'id') || gObjProp(card, 'user_id');
        updateCardLimits({
          userId: cardOwnerId,
          cardId: subscription.card_id,
          data: cardsHelpers.getSetLimitPayload(formData),
          headers: getAuthHeaders(action || activeAction),
          successCallback: () => {
            finishSubscriptionUpdate(true, false, true);
            finishPaymentDetailsLoading();
          },
          errorCallback: (response) => {
            SCAErrorCallback({
              response,
              errorCallback: () => {
                finishDetailsWindowLoading();
                setIsLoadingDetails(false);
              }
            });
          }
        })
      } else if (isUpdatedSubscription) {
        finishSubscriptionUpdate(true, false, false);
      } else {
        finishDetailsWindowLoading();
      }
    }

    // update subscription details if owner has been changed
    updateSubscription({
      id: subscription.id,
      data,
      successCallback: (subscription) => {
        handleUpdateCardLimits(subscription, true);
        logEvent(firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_EDIT_PAYMENT_DETAILS);
      },
      errorCallback: finishDetailsWindowLoading
    })
  }

  const startCardDetailsLoading = () => setCardDetails({...cardDetails, loading: true, processing: true});

  const finishCardDetailsLoading = () => setCardDetails({...cardDetails, loading: false, processing: false});

  const finishPaymentDetailsLoading = () => setIsLoadingPaymentDetails(false);

  const handleCancelAuthModal = () => {
    const actions = {
      [SUBSCRIPTION_CARD_CREATION_ACTION]: () => setAddCardModalProps({...addCardModalProps, loading: false}),
      [SUBSCRIPTION_EDIT_ACTION]: () => setIsLoadingDetails(false),
      [LOCK_CARD_ACTION]: () => {
        finishCardDetailsLoading();
        finishPaymentDetailsLoading();
      },
      [UNLOCK_CARD_ACTION]: () => {
        finishCardDetailsLoading();
        finishPaymentDetailsLoading();
      },
      [SUBSCRIPTION_CHANGE_PAYMENT_METHOD_ACTION]: () => {
        setChangeMethodModalProps({...changeMethodModalProps, loading: true});
        finishPaymentDetailsLoading();
      }
    }
    setAuthWindowProps({...authWindowProps, open: false});
    tempData && setTempData(null);
    actions.hasOwnProperty(activeAction) ? actions[activeAction]() : finishPaymentDetailsLoading();
  }

  const onSuccessAuth = () => {
    const actions = {
      [SUBSCRIPTION_CARD_CREATION_ACTION]: handleCreateCard,
      [CARD_EDITION_ACTION]: handleUpdatePaymentDetails,
      [SUBSCRIPTION_CARD_RECREATE_ACTION]: handleCardRetry,
      [LOCK_CARD_ACTION]: () => {
        startCardDetailsLoading();
        handleQuickAction({
          action: LOCK_CARD_ACTION,
          quickAction: subscriptionActionConstants.PAUSE,
          successCallback: finishCardDetailsLoading,
          errorCallback: finishCardDetailsLoading
        });
      },
      [UNLOCK_CARD_ACTION]: () => {
        startCardDetailsLoading();
        handleQuickAction({
          action: UNLOCK_CARD_ACTION,
          quickAction: subscriptionActionConstants.ACTIVATE_PAUSED,
          successCallback: finishCardDetailsLoading,
          errorCallback: finishCardDetailsLoading
        });
      },
      [SUBSCRIPTION_CHANGE_PAYMENT_METHOD_ACTION]: () => {
        handleUpdatePaymentMethod({
          action: SUBSCRIPTION_CHANGE_PAYMENT_METHOD_ACTION,
          data: tempData,
          isRequiredCardCreation: true
        });
      },
      [SUBSCRIPTION_EDIT_ACTION]: handleUpdateSubscription,
    }
    setAuthWindowProps({...authWindowProps, open: false});
    if (actions.hasOwnProperty(activeAction)) actions[activeAction]();
  }

  const displayError = (key) => {
    alertActions.error(`${t(`errors.${key}`)} ${t(`errors.contactAdministrator`)}`);
    finishDetailsWindowLoading();
  }

  const finishDetailsWindowLoading = () => {
    finishPaymentDetailsLoading();
    setIsLoadingBudgetDetails(false);
    setIsLoadingDetails(false);
  }

  const finishSubscriptionUpdate = (
    isNeedCardUpdate = false,
    isNeedTransactionUpdate = true,
    isChangedLimit = false
  ) => {
    finishDetailsWindowLoading();
    handleUpdateData && handleUpdateData(isNeedCardUpdate, isNeedTransactionUpdate, isChangedLimit);
  }

  const handleUpdateSubscription = (data, detailsType) => {
    let subscriptionData = data || tempData;
    const {id: subscriptionId} = subscription;
    if (typeof subscriptionId !== 'string') return;

    if (detailsType === 'budget') {
      setIsLoadingBudgetDetails(true);
    } else {
      setIsLoadingDetails(true);
    }
    // Set payment type to NOT_APPLICABLE is status has been changed to AUDITED/RESTRICTED/UNMANAGED
    if (subscriptionData.status && subscriptionsHelpers.isAuditedStatus(subscriptionData.status)) {
      subscriptionData = {
        ...subscriptionData,
        payment_type: NOT_APPLICABLE
      }
    }

    updateSubscription({
      id: subscriptionId,
      data: subscriptionData,
      headers: getAuthHeaders(SUBSCRIPTION_EDIT_ACTION),
      successCallback: (response) => {
        finishSubscriptionUpdate(response.payment_type === CARD, true, false);
        logEvent(firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_EDIT);
      },
      errorCallback: (response) => {
        SCAErrorCallback({
          response,
          errorCallback: finishDetailsWindowLoading
        });
      }
    });
  }

  const handleChangeCardStatus = (checked) => {
    const action = checked ? UNLOCK_CARD_ACTION : LOCK_CARD_ACTION;
    startCardDetailsLoading();
    setActiveAction(action);
    handleQuickAction({
      action,
      quickAction: checked ? subscriptionActionConstants.ACTIVATE_PAUSED : subscriptionActionConstants.PAUSE,
      successCallback: () => {
        const changeCardLogEvent = checked ? firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_ACTIVATE_CARD : firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_DEACTIVATE_CARD;
        logEvent(changeCardLogEvent);
        finishCardDetailsLoading();
      },
      errorCallback: finishCardDetailsLoading
    });
  }

  const handleAddCard = () => setAddCardModalProps({...addCardModalProps, open: true});

  const handleQuickAction = ({action, data, quickAction, successCallback, errorCallback} = {}) => {
    setIsLoadingPaymentDetails(true);
    subscriptionQuickAction({
      action: quickAction,
      data,
      headers: getAuthHeaders(action || activeAction),
      id: subscription.id,
      successCallback: () => {
        finishSubscriptionUpdate(true, false, false);
        successCallback && successCallback();
      },
      errorCallback: (response) => {
        SCAErrorCallback({
          response,
          errorCallback: () => {
            displayError('cardCreation');
            finishPaymentDetailsLoading();
            errorCallback && errorCallback();
          }
        });
      }
    });
  }

  const handleCardRetry = () => {
    setActiveAction(SUBSCRIPTION_CARD_RECREATE_ACTION);
    handleQuickAction({
      action: SUBSCRIPTION_CARD_RECREATE_ACTION,
      quickAction: subscriptionActionConstants.CARD_RETRY
    });
  }

  const handleOpenChangeMethodModal = () => setChangeMethodModalProps({...changeMethodModalProps, open: true});

  const handleChangeMethod = () => {
    const service = gObjProp(subscription, 'service');
    const serviceName = gObjProp(service, 'name') || gObjProp(service, 'url');
    if (isCardPaymentType) {
      Modal.confirm({
        title: t('modal.changePaymentMethodConfirm.title'),
        content: t('modal.changePaymentMethodConfirm.description', {name: serviceName}),
        icon: null,
        okButtonProps: {size: 'large', type: 'primary'},
        okText: t('main:yes'),
        cancelText: t('main:no'),
        cancelButtonProps: {className: 'green-btn-outlined', size: 'large'},
        onOk: handleOpenChangeMethodModal,
      });
    } else {
      handleOpenChangeMethodModal();
    }
  }

  const handleOkAddCardModal = (data) => {
    data = {
      ...data,
      payment_type: CARD,
      expected_first_payment: subscription?.expected_first_payment || dateHelpers.getMomentUnixTimestamp(moment())
    }
    setActiveAction(SUBSCRIPTION_CARD_CREATION_ACTION);
    setTempData(data);
    handleCreateCard({action: SUBSCRIPTION_CARD_CREATION_ACTION, data});
  }

  const handleCancelAddCardModal = () => setAddCardModalProps({...addCardModalProps, open: false, loading: false});

  const handleCancelChangeMethodModal = () => setChangeMethodModalProps({...changeMethodModalProps, open: false, loading: false});

  const handleOkChangeMethodModal = (data) => {
    const {payment_type: newPaymentType} = data;
    if (newPaymentType !== CARD && newPaymentType === paymentType) return;

    setActiveAction(SUBSCRIPTION_CHANGE_PAYMENT_METHOD_ACTION);
    setTempData(data);
    handleUpdatePaymentMethod({
      action: SUBSCRIPTION_CHANGE_PAYMENT_METHOD_ACTION,
      data,
      isRequiredCardCreation: true
    });
  }

  const subscriptionOwner = subscription ? gObjProp(subscription.owner, 'email') : undefined;

  return (
    <StyledOverviewTabContainer>
      {/* Display transaction details if payment type is card */}
      {isCardPaymentType && (
        <TransactionHeaderDetails
          data={subscription}
          transactionsCount={transactionsCount}
        />
      )}

      <StyledDetailsContainer>
        <StyledDetailsContent>
          <SpinSmall spinning={isLoadingDetails}>
            <SubscriptionDetails
              edit={edit}
              onSubmit={handleSubmitSubscriptionDetails}
              subscription={subscription}
            />
          </SpinSmall>
          <HistoryDetails
            subscription={subscription}
          />
        </StyledDetailsContent>
        <StyledDetailsContent>
          <SpinSmall spinning={isLoadingBudgetDetails}>
            <BudgetDetails
              edit={edit}
              onSubmit={(data) => handleUpdateSubscription(data, 'budget')}
              subscription={subscription}
            />
          </SpinSmall>
          <SpinSmall spinning={isLoadingContactDetails}>
            <ContractDetails
              edit={edit}
              onSubmit={handleSubmitContractDetails}
              subscription={subscription}
            />
          </SpinSmall>
          <SpinSmall spinning={isLoadingPaymentDetails}>
            <PaymentDetails
              cardDetails={cardDetails}
              edit={edit}
              subscription={subscription}
              handleChangeMethod={handleChangeMethod}
              handleChangeCardStatus={handleChangeCardStatus}
              handleRecreateCard={handleCardRetry}
              handleAddCard={handleAddCard}
              onFormSubmit={handleSubmitPaymentDetails}
              isOpenAuthModal={authWindowProps.open}
            />
          </SpinSmall>
        </StyledDetailsContent>
      </StyledDetailsContainer>

      <AddCardModal
        {...addCardModalProps}
        handleCancel={handleCancelAddCardModal}
        handleOk={handleOkAddCardModal}
        subscriptionOwner={subscriptionOwner}
      />

      <ChangeMethodModal
        {...changeMethodModalProps}
        defaultPaymentType={paymentType}
        excludePaymentTypes={isCardPaymentType ? [] : [CARD]}
        handleCancel={handleCancelChangeMethodModal}
        handleOk={handleOkChangeMethodModal}
        subscription={subscription}
      />

      <AuthenticationWindow
        {...authWindowProps}
        handleCancel={handleCancelAuthModal}
        onSuccess={onSuccessAuth}
        operationName={activeAction}
      />
    </StyledOverviewTabContainer>
  );
}

const mapDispatchToProps = {
  updateSubscription: subscriptionActions.updateSubscription,
  updateCardLimits: bankingActions.updateCardLimits,
  subscriptionQuickAction: subscriptionActions.subscriptionQuickAction
}

OverviewTab.propTypes = {
  edit: PropTypes.bool,
  handleUpdateData: PropTypes.func,
  transactionsCount: PropTypes.number
}

OverviewTab.defaultProps = {
  edit: true,
  transactionsCount: 0
}

export default connect(null, mapDispatchToProps)(OverviewTab);
