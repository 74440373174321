import {bankingConstants, companyConstants, mainConstants, userConstants} from '../constants';
import {request} from '../services/request';
import {authActions} from './auth.actions';
import {mainActions} from './main.actions';
import {transactionActions} from './transaction.actions';
import {localStorageKeysConstants} from '../../constants';
import {backendEndpoints} from '../../api';
import {objectHelpers, teamsHelpers} from '../../utils/helpers';

const {COMPANY_ID, CLOSED_BALANCE_AUTH, SHOW_WELCOME, TOKEN} = localStorageKeysConstants;

const errorHandlerProps = {disableAlert: true};

const authUser = (isAuth) => {
  return {
    type: userConstants.SET_AUTH,
    isAuth
  }
}

const clearUserData = () => {
  return {
    type: userConstants.CLEAR_USER_DATA
  }
}

const getEmployeeProfile = (errorFunc) => {
  return (dispatch, getState) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_USER_EMPLOYEE_CURRENT,
      successCallback: (data) => {
        const {role} = data;
        dispatch({
          type: userConstants.GET_EMPLOYEE_PROFILE,
          user: data,
          isAdmin: teamsHelpers.isAdmin(role),
          isSupport: teamsHelpers.isSupport(role),
          isKnownEmployee: true
        });
      },
      errorCallback: (data) => {
        try {
          if (data.response.status === 403) {
            const {companies} = getState().user;
            const currentCompany = companies.find(d => d.company_id !== localStorage.getItem(localStorageKeysConstants.COMPANY_ID));
            if (currentCompany) dispatch(changeCompany(currentCompany.company_id));
            errorFunc && errorFunc(data);
          }
        } catch (e) {
          errorFunc && errorFunc(data);
        }
        dispatch({
          type: userConstants.GET_EMPLOYEE_PROFILE,
          user: {},
          isAdmin: false,
          isSupport: false,
          isKnownEmployee: false
        });
        dispatch({type: userConstants.SET_CHECKED_AUTH});
      },
      errorHandlerProps
    });
  }
}

const getEmployeeSettings = (success, error) => {
  return (dispatch) => {
    const updateSettings = (data) => dispatch({ type: userConstants.GET_EMPLOYEE_SETTINGS, data });

    request.get({
      dispatch,
      url: backendEndpoints.GET_USER_CURRENT_SETTINGS,
      successCallback: (data) => {
        updateSettings(data);
        if (success) success();
      },
      errorCallback: () => {
        updateSettings({});
        if (error) error();
      },
      errorHandlerProps
    });
  }
}

const getEmployeeNotificationSettings = (success, error) => {

  return (dispatch) => {
    const updateSettings = (data) => dispatch({ type: userConstants.GET_EMPLOYEE_NOTIFICATION_SETTINGS, data });

    request.get({
      dispatch,
      url: backendEndpoints.GET_USER_CURRENT_NOTIFICATIONS,
      successCallback: (data) => {
        updateSettings(data);
        if (success) success();
      },
      errorCallback: () => {
        updateSettings({});
        if (error) error();
      },
      errorHandlerProps
    });
  }

}

const getUserProfile = (hideLoader = true, showLoading = true) => {
  return (dispatch) => {
    const loading = (status = false) => mainActions.setInitialLoading(status, dispatch);
    const token = JSON.parse(localStorage.getItem(TOKEN));

    const clearStoredSession = () => {
      authActions.logout();
      dispatch(userActions.markedCheckedAuth());
      loading();
    }

    if (token && token.accessToken) {
      showLoading && loading(true);
      request.get({
        dispatch,
        url: backendEndpoints.GET_USER_CURRENT_PROFILE,
        successCallback: (data) => {
          if (localStorage.getItem(SHOW_WELCOME) && data.has_any_company) {
            localStorage.removeItem(SHOW_WELCOME);
          }
          dispatch({
            type: userConstants.GET_USER_PROFILE,
            user: data,
          });
          hideLoader && loading();
        },
        errorCallback: clearStoredSession,
        errorHandlerProps
      });
    } else {
      clearStoredSession();
    }
  }
}

const getCompanies = (handleChangeCompany, callbackHandle) => {
  return (dispatch, getState) => {

    const updateCompanies = (companies) => dispatch({ type: userConstants.GET_COMPANIES, companies });

    const setCompany = (data) => dispatch({ type: companyConstants.SET_COMPANY, data });

    const onChangeCompany = (company) => handleChangeCompany && handleChangeCompany(company);

    request.get({
      dispatch,
      url: backendEndpoints.GET_COMPANIES,
      successCallback: (data) => {
        const {user, company} = getState();

        updateCompanies(data.sort((a, b) => a.name.localeCompare(b.name)));

        if (data.length === 0) {
          localStorage.setItem(SHOW_WELCOME, 'true');
        }

        if (company.id === null) {
          const companyId = objectHelpers.getObjProp(user.employee, 'company_id');
          let currentCompany = data.find(d => d.company_id === companyId && d.account_enabled);
          const firstActiveCompany = data.find(d => d.account_enabled);
          if (currentCompany === undefined && data.length > 0) {
            const company = localStorage.getItem(COMPANY_ID);
            if (company) {
              let foundCompany = data.find(d => d.company_id === company && d.account_enabled);
              if (foundCompany) {
                currentCompany = foundCompany;
              } else {
                currentCompany = firstActiveCompany;
                onChangeCompany(data.find(d => d.company_id === company));
              }
            } else {
              currentCompany = firstActiveCompany;
            }
            currentCompany && dispatch(changeCompany(currentCompany.company_id));
          } else {
            if (companyId) {
              setCompany(currentCompany || {});
            } else {
              const company = firstActiveCompany;
              if (company) setCompany(company);
            }
          }
        } else {
          const companyId = objectHelpers.getObjProp(user.employee, 'company_id');
          const currentCompany = data.find(d => d.company_id === companyId && d.account_enabled);
          currentCompany && setCompany(currentCompany);
        }
        callbackHandle && callbackHandle(data);
      },
      errorCallback: (data) => {
        updateCompanies([]);
        callbackHandle && callbackHandle(data);
      },
      errorHandlerProps
    });
  }
}

const changeCompany = (companyId, errorFunc) => {
  return (dispatch, getState) => {
    const {companies} = getState().user;
    const currentCompany = companies.find(d => d.company_id === companyId);
    dispatch({
      type: companyConstants.SET_COMPANY,
      data: currentCompany || {}
    });
    dispatch({
      type: bankingConstants.CLEAR_CARDS_ADDITIONAL_DATA
    });
    dispatch({
      type: userConstants.CLEAR_NOTIFICATIONS
    });
    dispatch({type: mainConstants.CLEAR_MAIN_DATA});
    dispatch(transactionActions.clearBankingDetails());
    localStorage.setItem(COMPANY_ID, companyId);
    localStorage.removeItem(CLOSED_BALANCE_AUTH)
    if (currentCompany && errorFunc) {
      dispatch(userActions.getEmployeeProfile(() => errorFunc(currentCompany)))
    } else {
      dispatch(userActions.getEmployeeProfile())
    }
  }
}

const markedCheckedAuth = () => {
  return (dispatch) => {
    dispatch({type: userConstants.SET_CHECKED_AUTH});
  }
}

const changeEmployeePassword = (data, successCallback, errorCallback) => {
  return (dispatch) => {
    request.put({
      dispatch,
      url: backendEndpoints.PUT_USER_CURRENT_CHANGE_PASSWORD,
      data,
      successCallback,
      errorCallback: (errors) => {
        let data;
        try {
          data = errors.response.data;
        } catch {}
        errorCallback && errorCallback(data);
      },
      errorHandlerProps
    });
  }
}

const updateEmployeeEmailNotifications = (data, successCallback, errorCallback) => {
  return (dispatch) => {
    request.put({
      dispatch,
      url: backendEndpoints.PUT_USER_CURRENT_UPDATE_EMAIL_NOTIFICATIONS,
      data,
      successCallback,
      errorCallback
    });
  }
}

const updateEmployeeInformation = (data, success, error) => {
  return (dispatch) => {
    request.put({
      dispatch,
      url: backendEndpoints.PUT_USER_CURRENT_SETTINGS,
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (resp) => error && error(resp),
      errorHandlerProps
    });
  }
}

const getUnreadNotifications = () => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_NOTIFICATIONS_COMPANY_UNREAD,
      successCallback: (data) => {
        dispatch({
          type: userConstants.SUCCESS_GET_UNREAD_NOTIFICATIONS_BY_COMPANY,
          data: data.companies.filter(c => c.has_notifications).map(c => c.company_id)
        });
      },
      errorCallback: () => dispatch({type: userConstants.ERROR_GET_UNREAD_NOTIFICATIONS_BY_COMPANY}),
      errorHandlerProps
    });
  }
}

const getNotificationList = (loadMore = false, successFunc, errorFunc) => {
  const updateNotifications = (data) => {
    return {type: userConstants.GET_NOTIFICATIONS_LIST, data}
  }
  const updateNotificationsCount = (data) => {
    return {type: userConstants.GET_NOTIFICATIONS_COUNT, data}
  }
  return (dispatch, getState) => {

    const loadNotificationsList = () => {
      let url = backendEndpoints.GET_NOTIFICATIONS;
      if (loadMore) {
        const {next_page: nextPage, cursor} = getState().user.notifications.pagination;
        if (nextPage) url += `?cursor=${cursor}`;
      }
      request.get({
        dispatch,
        url,
        successCallback: (data) => {
          const {notifications, pagination} = data;
          dispatch(updateNotifications({
            pagination,
            notifications: loadMore ? [...getState().user.notifications.notifications, ...notifications] : notifications
          }));
          dispatch(getUnreadNotifications());
          successFunc && successFunc();
        },
        errorCallback: () => {
          dispatch(updateNotifications([]));
          errorFunc && errorFunc();
        }
      });
    }
    const {notificationCount} = getState().user
    request.get({
      dispatch,
      url: backendEndpoints.GET_NOTIFICATIONS_COUNT,
      successCallback: (data) => {
        const {total_read: read, total_unread: unread} = data;
        if ((notificationCount.read !== read || notificationCount.unread !== unread) || loadMore) {
          dispatch(updateNotificationsCount({read, unread}));
          loadNotificationsList();
        } else {
          successFunc && successFunc();
        }
      },
      errorCallback: () => {
        dispatch(updateNotificationsCount({read: 0, unread: 0}))
        errorFunc && errorFunc();
      },
      errorHandlerProps
    });
  }
}

const readNotifications = (ids, success, error) => {
  return (dispatch, getState) => {
    request.put({
      dispatch,
      url: backendEndpoints.PUT_NOTIFICATIONS_STATUS_UPDATE,
      data: {notifications_ids: ids},
      successCallback: () => {
        const {notifications, notificationCount} = getState().user;
        let foundNotificationCount = 0;
        const notificationList = notifications.notifications.map(n => {
          if (ids.includes(n.id)) {
            foundNotificationCount++;
            return {...n, is_read: true}
          } else {
            return n;
          }
        });
        dispatch({
          type: userConstants.READ_NOTIFICATIONS,
          notifications: {
            ...notifications,
            notifications: notificationList
          },
          notificationCount: {
            read: notificationCount.read + foundNotificationCount,
            unread: notificationCount.unread - foundNotificationCount
          }
        });
        if (success) success();
      },
      errorCallback: (errors) => {
        const {data} = errors.response;
        if (error) error(data);
      },
      errorHandlerProps
    });
  }
}

const readAllNotifications = (success, error) => {
  return (dispatch, getState) => {
    request.put({
      dispatch,
      url: backendEndpoints.PUT_NOTIFICATIONS_READ_ALL,
      successCallback: (data) => {
        const {notifications, notificationCount, employee, companiesWithNotifications} = getState().user;
        dispatch({
          type: userConstants.READ_ALL_NOTIFICATIONS,
          notifications: {
            ...notifications,
            notifications: notifications.notifications.map(n => {
              return {...n, is_read: true}
            })
          },
          notificationCount: {
            read: notificationCount.read + notificationCount.unread,
            unread: 0
          }
        });
        dispatch({
          type: userConstants.SUCCESS_GET_UNREAD_NOTIFICATIONS_BY_COMPANY,
          data: companiesWithNotifications.filter(c => c !== employee.company_id)
        })
        success && success(data);
      },
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    })
  }
}

const disableEmployeeFromCompany = (isDisabled = false, company) => {
  return (dispatch) => {
    dispatch({
      type: userConstants.DISABLED_FROM_COMPANY,
      data: isDisabled ? {
        isDisabled,
        company
      } : null
    });
  }
}

const sendEmailVerification = (email, success, error) => {
  return verificationRequestTemplate(
    {email},
    backendEndpoints.PUT_USER_CURRENT_EMAIL_VERIFICATION_START,
    'SEND_USER_EMAIL_VERIFICATION',
    success,
    error,
    false
  );
}

const finishEmailVerification = (token, success, error) => {
  return verificationRequestTemplate(
    {token},
    backendEndpoints.PUT_USER_CURRENT_EMAIL_VERIFICATION_FINISH,
    'FINISH_USER_EMAIL_VERIFICATION',
    success,
    error,
    false,
    errorHandlerProps
  );
}

const startPhoneVerification = ({data, successCallback, errorCallback}) => phoneVerificationTemplate(data, backendEndpoints.PUT_USER_CURRENT_PHONE_VERIFICATION_START, successCallback, errorCallback);

const endPhoneVerification = (data, success, error) => phoneVerificationTemplate(data, backendEndpoints.PUT_USER_CURRENT_PHONE_VERIFICATION_FINISH, success, error, errorHandlerProps);

const verificationRequestTemplate = (data, url, dispatchTypeName, success, error, showLoader = true, errorHandlerProps = {}) => {
  return (dispatch) => {
    const loading = (loadingStatus) => showLoader && mainActions.setLoading(loadingStatus, dispatch);
    loading(true);
    request.put({
      dispatch,
      url,
      data,
      successCallback: (data) => {
        dispatch({
          type: userConstants[`SUCCESS_${dispatchTypeName}`],
          data
        });
        loading(false);
        if (success) success(data);
      },
      errorCallback: (err) => {
        const {data} = err.response;
        if (error) error(data);
        loading(false);
        dispatch({
          type: userConstants[`ERROR_${dispatchTypeName}`],
          data
        });
      },
      errorHandlerProps
    });
  }
}

const phoneVerificationTemplate = (data, url, success, error, requestProps) => {
  return (dispatch) => {
    request.post({
      dispatch,
      url,
      data,
      successCallback: (response) => success && success(response),
      errorCallback: (err) => {
        if (err && err.hasOwnProperty('response') && err.response !== undefined) {
          data = err.response.data;
          error && error(data);
        }
      },
      errorHandlerProps: requestProps
    });
  }
}

export const userActions = {
  authUser,
  clearUserData,
  getEmployeeProfile,
  getEmployeeSettings,
  getEmployeeNotificationSettings,
  getUserProfile,
  getCompanies,
  markedCheckedAuth,
  changeCompany,
  changeEmployeePassword,
  updateEmployeeEmailNotifications,
  updateEmployeeInformation,
  getNotificationList,
  readNotifications,
  readAllNotifications,
  sendEmailVerification,
  finishEmailVerification,
  disableEmployeeFromCompany,

  startPhoneVerification,
  endPhoneVerification,
};
