import styled from 'styled-components';
import {Space} from 'antd';
import {typographyStyles} from '../../../../../../utils/styles';

const StyledHistoryDetails = styled(Space)`
  & > .ant-space-item {
    width: 100%;
    
    button {
      margin: auto;
    }
  }
`;

const StyledHistoryDetailsButtonContainer = styled.div`
  min-height: 40px;
  text-align: center;
`;

const StyledHistoryDetailsMessage = styled(Space)`
  ${typographyStyles.body.regular};
`;

const StyledHistoryDetailsMessageFieldValue = styled.span`
  font-weight: 500;
  word-break: break-all;
`;

export {
  StyledHistoryDetails,
  StyledHistoryDetailsButtonContainer,
  StyledHistoryDetailsMessage,
  StyledHistoryDetailsMessageFieldValue
}
