import { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import {useIsRequiredCardVerification} from './useIsRequiredCardVerification';

export const useIsEnabledUserOverview = () => {
  const {isAdmin, navigationLoading, navigationMenuProps} = useSelector(state => ({
    isAdmin: state?.user?.isAdmin,
    navigationMenuProps: state?.main?.navigationMenuProps,
    navigationLoading: state?.main?.navigationLoading || false
  }));
  const [enabled, setEnabled] = useState(false);

  const {
    required: isRequiredCardVerification
  } = useIsRequiredCardVerification();

  useEffect(() => {
    const isEnabled = !isAdmin && (isRequiredCardVerification ? true : (!navigationLoading && !Boolean((navigationMenuProps?.items || []).length)));
    if (isEnabled !== enabled) setEnabled(isEnabled);
  }, [isAdmin, isRequiredCardVerification, navigationLoading, navigationMenuProps]); // eslint-disable-line react-hooks/exhaustive-deps

  return enabled;
}

