import styled from 'styled-components';
import {Space} from 'antd';
import Spin from '../../components/Spin';

const StyledOverviewUserPageContent = styled(Space)`
  padding: 24px;
  width: 100%;
  
  & > .ant-space-item {
    width: 100%;
  }
`;

const StyledOverviewUserPageSpin = styled(Spin)`
  min-height: calc(100vh - 66px);
  max-height: unset !important;
`;


export {
  StyledOverviewUserPageContent,
  StyledOverviewUserPageSpin
}