import styled from 'styled-components';
import {typographyStyles} from '../../../../utils/styles';

const StyledBalanceDetails = styled.span`
  ${typographyStyles.body.regular};
  color: ${props => props.theme.colors.neutral_10};
  margin-right: 6px;
  
  .balance-value {
    ${typographyStyles.body.bold};
  }
  
  .ant-space-item {
    display: flex;
  }
  
  svg {
    height: 24px;
    width: 24px;
    
    path {
      fill: ${props => props.theme.colors.neutral_10};
    }
  }
`;

const StyledBalanceDetailsWarning = styled.div`
  ${typographyStyles.body.regular};
  color: ${props => props.theme.colors.neutral_10};
  
  .ant-space-item {
    display: flex;
    
    & > svg {
      path {
        fill: ${props => props.theme.colors.functional_warning};
      }
    }
  }
`;

export {
  StyledBalanceDetails,
  StyledBalanceDetailsWarning
};
