import styled from 'styled-components';

const StyledMainNavigation = styled.div`
  .ant-menu {
    height: calc(100vh - (66px + 60px + 16px));
    overflow: auto;
  }
`;

const StyledMainNavigationMenuIcon = styled.span`
  svg {
    height: 16px;
    width: 16px;
  }
`;


export {
  StyledMainNavigation,
  StyledMainNavigationMenuIcon,
}