import React, {useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {UnmountClosed} from 'react-collapse';
import {useNavigate} from 'react-router-dom';
import {StyledOverviewUserPageContent, StyledOverviewUserPageSpin} from './StyledOverviewUserPage';
import {StyledOverviewPageContainer} from '../OverviewPage/StyledOverviewPage';
import Empty from '../../components/Empty';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import CompleteKycCard from '../../components/pages/OverviewPage/CompleteKycCard';
import {ReactComponent as EmptySvgImage} from '../../static/images/pages/subscriptions/empty-subscriptions.svg';
import {useIsRequiredCardVerification} from '../../hooks';


const OverviewUserPage = ({
  navigationLoading,
  navigationMenuProps,
}) => {
  const [t] = useTranslation('main');
  const navigate = useNavigate();

  const trans = (key) => t(`overviewPage.empty.user.${key}`);

  const {
    checked: isCheckedBankingDetails,
    required: isRequiredCardVerification
  } = useIsRequiredCardVerification();

  const isEnabledEmpty = useMemo(
    () => !isRequiredCardVerification && isCheckedBankingDetails && !navigationLoading,
    [isCheckedBankingDetails, isRequiredCardVerification, navigationLoading]
  );

  useEffect(() => {
    const items = navigationMenuProps?.items || [];
    if (items.length) {
      let firstItemWithPath = items.find(i => i.path && i.variant === 'card' && !i.disabled);
      // redirect to fist available card page
      if (firstItemWithPath && isCheckedBankingDetails && !isRequiredCardVerification) {
        navigate(firstItemWithPath.path);
      }
    }
  }, [navigationMenuProps, isCheckedBankingDetails, isRequiredCardVerification]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledOverviewPageContainer>
      <PageDocumentDetails title={t('pageTitles.overview')} />
      <BalancePageHeader
        breadcrumbs={[{title: t('overview')}]}
      />
      <StyledOverviewUserPageSpin
        size='small'
        spinning={navigationLoading}
      >
        <StyledOverviewUserPageContent
          align='start'
          direction='vertical'
          size='large'
          style={{width: '100%'}}
        >
          <UnmountClosed isOpened={isRequiredCardVerification}>
            <CompleteKycCard variant='banking' />
          </UnmountClosed>
          {isEnabledEmpty && (
            <Empty
              description={trans('description')}
              image={<EmptySvgImage/>}
              title={trans('title')}
            />
          )}
        </StyledOverviewUserPageContent>
      </StyledOverviewUserPageSpin>
    </StyledOverviewPageContainer>
  );
}

const mapStateToProps = state => {
  const {main} = state;
  const navigationLoading = main?.navigationLoading || false;
  const navigationMenuProps = main?.navigationMenuProps;

  return {
    navigationLoading,
    navigationMenuProps,
  }
}

export default connect(mapStateToProps, null)(OverviewUserPage);
