import React, {useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Button, Space} from 'antd';
import {StyledBalanceDetails, StyledBalanceDetailsWarning} from './StyledBalancePageHeader';
import AddFundsDetails from '../../SubscriptionsPage/AddFundsDetails';
import PageHeader from '../../../Header/PageHeader';
import BalanceLoading from '../BalanceLoading';
import {WalletIcon, WarningIcon} from '../../../../icons';
import {amountHelpers, elementHelpers, objectHelpers} from '../../../../utils/helpers';
import {localStorageKeysConstants} from '../../../../constants';

const gObjProp = objectHelpers.getObjProp;

const BalancePageHeader = ({
  balanceDetails,
  className,
  employees,
  isAdmin,
  isEnabledBanking,
  wireDetails,
  dispatch,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [enabledLoading, setEnabledLoading] = useState(false);

  const cName = elementHelpers.getClassName('balance-page-header', className);

  const handleRetry = () => {
    localStorage.removeItem(localStorageKeysConstants.CLOSED_BALANCE_AUTH);
    setEnabledLoading(true);
  }

  const getHeaderButtons = () => {
    const buttons = [];
    if (isEnabledBanking && isAdmin) {
      if (balanceDetails.isLoaded) {
        const currency = gObjProp(balanceDetails, 'currency', 'EUR');
        const value = amountHelpers.getAmountWithCurrencyCode(gObjProp(balanceDetails, 'value', 0), currency);
        buttons.push(
          <StyledBalanceDetails>
            <Space align='center' size='small'>
              <span>{t('balance')}:</span>
              <Space align='center' size='small'>
                <WalletIcon />
                <span className='balance-value'>
                  {value}
                </span>
              </Space>
            </Space>
          </StyledBalanceDetails>
        );
        if (isAdmin) {
          buttons.push((
            <AddFundsDetails
              details={wireDetails}
            />
          ));
        }
      } else if (balanceDetails.isRequested) {
        buttons.push(
          <StyledBalanceDetailsWarning>
            <Space align='center' size='middle'>
              <Space align='center' size='small'>
                <WarningIcon />
                <span>{t('authenticateToSeeTheBalance')}</span>
              </Space>
              <Button
                className='primary-outline'
                onClick={handleRetry}
                loading={enabledLoading}
              >
                {t('retry')}?
              </Button>
            </Space>
          </StyledBalanceDetailsWarning>
        );
      }
    }
    return buttons;
  }

  const headerButtons = getHeaderButtons();

  const balanceLoadingCallback = () => {
    setEnabledLoading(false);
  }

  return (
    <>
      <PageHeader
        buttons={headerButtons}
        className={cName}
        {...rest}
      />

      {isAdmin && (
        <BalanceLoading
          enabledLoading={enabledLoading}
          errorCallback={balanceLoadingCallback}
          successCallback={balanceLoadingCallback}
        />
      )}
    </>
  );
}

const mapStateToProps = state => {
  const {isAdmin} = state.user;
  const {balanceDetails, wireDetails} = state.transaction;
  const {isEnabledBanking} = state.banking;

  return {
    balanceDetails,
    isEnabledBanking,
    isAdmin,
    wireDetails
  }
}


export default connect(mapStateToProps, null)(BalancePageHeader);
