import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import HeaderDetails from '../../components/pages/SubscriptionPage/HeaderDetails';
import TransactionsTab from '../../components/pages/SubscriptionPage/tabs/TransactionsTab';
import OverviewTab from '../../components/pages/SubscriptionPage/tabs/OverviewTab';
import UsageReportTab from '../../components/pages/SubscriptionPage/tabs/UsageReportTab';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import NotFoundPage from '../NotFoundPage';
import {
  bankingActions,
  companyActions,
  expenseActions,
  subscriptionActions,
  transactionActions,
} from '../../state/actions';
import {StyledSubscriptionPageContainer, StyledTabs} from './StyledSubscriptionPage';
import SpinSmall from '../../components/SpinSmall';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader/BalancePageHeader';
import AuthorizeSubscriptionModal
  from '../../components/pages/SubscriptionsPage/AuthorizeSubscriptionModal/AuthorizeSubscriptionModal';
import DeleteModal from '../../components/pages/SubscriptionPage/DeleteModal';
import AuthenticationWindow from '../../components/pages/CardsPage/AuthenticationWindow';
import PageContainer from '../../components/PageContainer';
import WebSocket from '../../components/WebSocket';
import {
  backendWebsocketActionsConstants,
  cardBackendStatusesConstants,
  subscriptionActionConstants,
  subscriptionStatusesConstants,
  SCAActionsConstants,
  transactionsRequestLimit,
} from '../../constants';
import routes from '../../routes/routes.json';
import {
  objectHelpers,
  scaHelpers,
  subscriptionsHelpers,
  systemHelpers,
  transactionsHelpers
} from '../../utils/helpers';
import {firebaseEvents} from '../../snippets/firebase';
import {useIsEmployeeLoaded, useHasAccess, useIsEnabledUserOverview} from '../../hooks';

const {logEvent} = systemHelpers;
const {getObjProp: gObjProp} = objectHelpers;

const {UNLOCK, LOCK} = cardBackendStatusesConstants;
const {INACTIVE, ACTIVE, DELETED} = subscriptionStatusesConstants;

const {AUTHORIZE, DELETE} = subscriptionActionConstants;

const {
  SUBSCRIPTION_CHANGE_STATUS_ACTION,
  SUBSCRIPTION_DELETE,
  SUBSCRIPTION_SUBMIT,
  TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION,
  TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION
} = SCAActionsConstants;

const defaultModalProps = {loading: false, open: false};

const SubscriptionPage = ({
  getEmployees,
  getTags,
  getSubscription,
  clearSubscription,
  getCardDetails,
  subscriptionQuickAction,
  deleteSubscription,
  getBatchExpenses,
  getSubscriptionTransactionsList,
  getUserDetails,
  setCardHold,
  isAdmin,
  employeeId,
}) => {
  const {subscriptionId} = useParams();
  const [t] = useTranslation(['main', 'subscriptions', 'cards']);
  const [card, setCard] = useState(null);
  const [pageLoading, setPageLoading] = useState({loading: true, isExist: false});
  const [transactions, setTransactions] = useState({transactions: [], isLoaded: false});
  const [upgradeModalProps, setUpgradeModalProps] = useState(defaultModalProps);
  const [subscription, setSubscription] = useState(null);
  const [expenses, setExpenses] = useState([]);
  const [loadMoreProps, setLoadMoreProps] = useState({cursor: null, loading: false, isEnabled: false});
  const [activeTab, setActiveTab] = useState('overview');
  const [enabledWebSocketConnection, setEnabledWebSocketConnection] = useState(false);
  const [deleteModalProps, setDeleteModalProps] = useState(defaultModalProps);
  const [subsAuthorizeModalProps, setSubsAuthorizeModalProps] = useState(defaultModalProps);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [activeAuthAction, setActiveAuthAction] = useState(null);
  const [activeStatusChangeOperationName, setActiveStatusChangeOperationName] = useState(null);
  const [tempData, setTempData] = useState(undefined);

  const finishLoading = (isExist = true) => setPageLoading({...pageLoading, isExist, loading: false});

  const finishTransactionsLoading = () => setTransactions({...transactions, isLoaded: true});

  const isEmployeeLoaded = useIsEmployeeLoaded();

  const hasEditAccess = useHasAccess('PUT_SUBSCRIPTION_UPDATE');
  const isEnabledEdit = useMemo(() => hasEditAccess || subscription?.owner?.id === employeeId, [hasEditAccess, employeeId, subscription]);
  const authModalProps = useMemo(() => scaHelpers.getAuthModalProps({action: activeAuthAction, t}), [activeAuthAction, t]);

  const pageTitle = useMemo(() => {
    let title = '';
    if (!pageLoading.isExist && !pageLoading.loading) {
      title = t('elementNotFound', {name: t('subscriptions:subscription')});
    } else {
      title = subscriptionsHelpers.getSubscriptionName(subscription);
    }
    return title;
  }, [pageLoading, subscription, t]);

  const isEnabledUserOverview = useIsEnabledUserOverview();

  const breadcrumbs = useMemo(() => {
    const defaultBreadcrumbs = [
      {title: t('navigation.subscriptions'), href: routes.subscriptionsList},
      {title: pageTitle}
    ];
    return systemHelpers.getUserBreadcrumbs({defaultBreadcrumbs, isAdmin, isEnabledUserOverview, t});
  }, [isAdmin, isEnabledUserOverview, pageTitle, t]);

  useEffect(() => {
    if (isEmployeeLoaded) {
      getEmployees();
      getTags();
    }
  }, [isEmployeeLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => clearSubscription();
  }, [clearSubscription]);

  useEffect(() => {
    if (subscription) {
      if (!transactions.isLoaded && activeTab === 'transactions') getTransactions();
    }
  }, [activeTab, subscription]);  // eslint-disable-line react-hooks/exhaustive-deps

  const handleStartLoading = () => setPageLoading({...pageLoading, loading: true});

  const loadCardDetails = ({cardId, cardOwnerId, subscription, successCallback}) => {
    getCardDetails(
      cardOwnerId,
      cardId,
      (card) => {
        setCard(card);
        successCallback && successCallback();
      },
      () => {
        setSubscription({
          ...subscription,
          card_id: null
        });
      }
    );
  }

  const getSubscriptionInfo = ({
    id,
    isNeedCardLoading,
    isNeedClearTransactions = true,
    isChangedLimit = false,
    successCallback
  }) => {
    if (!pageLoading.loading) handleStartLoading();
    setUpgradeModalProps({...upgradeModalProps, loading: false, open: false});
    getSubscription(
      id,
      (subscription) => {
        if (isNeedCardLoading) {
          const {card_id: cardId} = subscription;
          const cardOwnerId = gObjProp(subscription?.card_owner, 'id');
          if (cardId) {
            // get card details
            loadCardDetails({cardId, cardOwnerId, subscription, successCallback});
            // get card transactions
            if (isNeedClearTransactions) setTransactions({isLoaded: false, transactions: []});

          } else {
            setEnabledWebSocketConnection(true);
            if (!isAdmin) {
              handleSetCardHoldDetails();
            } else {
              if (cardOwnerId) {
                getUserDetails(
                  cardOwnerId,
                  () => handleSetCardHoldDetails(),
                  () => handleSetCardHoldDetails(true, t('cards:errors.theUserNeedToFinishLightKYC'))
                );
              } else {
                handleSetCardHoldDetails();
              }
            }
            successCallback && successCallback();
          }
          finishLoading();
        } else {
          finishLoading();
        }
        setSubscription(subscription);
        // update card limit while card details haven't updated yet
        if (card && isChangedLimit) {
          setCard({...card, limit_payment_month: subscription.budget_limit});
        }
      },
      () => finishLoading(false)
    )
  }

  const getTransactions = () => {
    loadTransactionsList({
      operationName: TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION,
      query: {},
      successCallback: getTransactionsSuccessCallback,
      errorCallback: finishTransactionsLoading
    });
  }

  useEffect(() => {
    if (subscriptionId) {
      getSubscriptionInfo({
        id: subscriptionId,
        isNeedCardLoading: true,
        isNeedClearTransactions: false
      });
      setTransactions({transactions: [], isLoaded: false});
    }
  }, [subscriptionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateSubscriptionInfo = (
    isNeedCardLoading = true,
    isNeedClearTransactions = true,
    isChangedLimit = false
  ) => {
    getSubscriptionInfo({id: subscriptionId, isNeedCardLoading, isNeedClearTransactions, isChangedLimit});
  }

  const handleUpdateCardStatus = (updatedCard, needFinishLoading) => {
    if (subscription) {
      const {status_code: cardStatus} = updatedCard;
      let subscriptionStatus;
      switch (cardStatus) {
        case LOCK:
          subscriptionStatus = INACTIVE;
          break;
        case UNLOCK:
          subscriptionStatus = ACTIVE;
          break;
        default:
          subscriptionStatus = ACTIVE;
      }
      setSubscription({
        ...subscription,
        status: subscriptionStatus
      });
    }
    card && setCard({...card, ...updatedCard});
    if (needFinishLoading) finishLoading();
  }

  const getTransactionsSuccessCallback = (response) => {
    const responseTransactions = response?.results || [];
    const cursor = response?.pagination?.next_cursor || undefined;
    const expensesIds = responseTransactions.map(t => t.expense_id).filter(value => value);

    setTransactions({transactions: [...transactions.transactions, ...responseTransactions], isLoaded: true});
    getBatchExpenses(
      {expenses_ids: expensesIds},
      (responseExpenses) => setExpenses([...expenses, ...responseExpenses])
    );
    setLoadMoreProps({
      ...loadMoreProps,
      cursor,
      loading: false,
      isEnabled: Boolean(cursor),
    });
  }

  const handleLoadMore = () => {
    const cardId = gObjProp(subscription, 'card_id');
    if (loadMoreProps.isEnabled) {
      const cursor = loadMoreProps?.cursor;
      setLoadMoreProps({...loadMoreProps, loading: true});

      loadTransactionsList({
        operationName: TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION,
        query: { card_id_in: [cardId], cursor },
        successCallback: (response) => getTransactionsSuccessCallback(response),
        errorCallback: () => setLoadMoreProps({...loadMoreProps, loading: false})
      });
    }
  }

  const loadTransactionsList = ({
    query,
    successCallback,
    errorCallback,
    operationName
  }) => {
    const requestQuery = {
      sort_order: 'desc',
      limit: transactionsRequestLimit,
      ...query
    }
    if (operationName !== activeAuthAction) setActiveAuthAction(operationName);
    getSubscriptionTransactionsList({
      headers: scaHelpers.getAuthHeaders(operationName || activeAuthAction),
      id: subscription.id,
      query: requestQuery,
      successCallback,
      errorCallback: (response) => {
        scaHelpers.SCAResponseCallback({
          response,
          scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
          errorCallback
        });
      }
    });
  }

  const scaResponseCallback = ({response, errorCallback}) => {
    scaHelpers.SCAResponseCallback({
      response,
      scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
      errorCallback
    });
  }

  const handleQuickSubscriptionAction = ({action, authAction, data, successCallback, errorCallback} = {}) => {
    if (action !== DELETE) setPageLoading({...pageLoading, loading: true});
    const subscriptionId = subscription.id;
    const successCallbackFunction = successCallback || tempData.successCallbackFunction;
    const errorCallbackFunction = errorCallback || tempData.errorCallbackFunction;

    if (data) {
      setTempData(data);
    } else {
      data = tempData;
    }

    (action === DELETE ? deleteSubscription : subscriptionQuickAction)({
      action,
      headers: authAction ? scaHelpers.getAuthHeaders(authAction) : undefined,
      id: subscriptionId,
      data,
      successCallback: () => {
        if (action === DELETE) {
          setSubscription({
            ...subscription,
            status: DELETED
          });
        } else if (authAction === SUBSCRIPTION_CHANGE_STATUS_ACTION) {
          const statuses = {
            [subscriptionActionConstants.PAUSE]: LOCK
          };
          const cardData = {
            status_code: statuses[action] || UNLOCK
          };
          handleUpdateCardStatus && handleUpdateCardStatus(cardData, true);
        } else {
          handleUpdateSubscriptionInfo(true, false);
        }
        finishLoading();
        successCallbackFunction && successCallbackFunction();
        setTempData(undefined);
      },
      errorCallback: (response) => {
        scaResponseCallback({
          response,
          errorCallback: (resp) => {
            errorCallbackFunction && errorCallbackFunction(resp);
            finishLoading();
          }
        });
      }
    });
  }

  const handleChangeStatus = ({action, data, successCallback, errorCallback} = {}) => {
    const formData = data || tempData;
    setTempData({
      ...tempData,
      data,
      successCallbackFunction: successCallback,
      errorCallbackFunction: errorCallback
    });
    setActiveAuthAction(SUBSCRIPTION_CHANGE_STATUS_ACTION);
    action && setActiveStatusChangeOperationName(action);
    handleQuickSubscriptionAction({
      action: action || activeStatusChangeOperationName,
      authAction: SUBSCRIPTION_CHANGE_STATUS_ACTION,
      data: formData,
      successCallback,
      errorCallback
    });
  }

  const onExpenseUpdate = (updatedExpense, actionType) => {
    if (updatedExpense) {
      let updatedExpenses = transactionsHelpers.getUpdatedBatchExpensesList(expenses, updatedExpense, actionType);
      setExpenses(updatedExpenses);
    }
  }

  const handleOnTabChange = (activeTabName) => {
    const tabLogEvents = {
      'transactions': firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_VIEW_TRANSACTIONS_TAB,
      'usage-report': firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_VIEW_USAGE_REPORT_TAB
    };
    const tabLogEvent = tabLogEvents[activeTabName];
    tabLogEvent && logEvent(tabLogEvent);
    setActiveTab(activeTabName);
  }

  const handleSubmitModal = ({action, authAction, data, getter, logEventName, setter}) => {
    const formData = data || tempData;
    if (data) setTempData({...tempData, data});
    setter({...getter, loading: true});
    setActiveAuthAction(authAction);
    handleQuickSubscriptionAction({
      action,
      authAction,
      data: formData,
      successCallback: () => {
        setter({...getter, loading: false, open: false});
        logEventName && logEvent(logEventName);
      },
      errorCallback: () => setter({...getter, loading: false})
    });
  }

  const handleCloseSubsAuthModal = () => setSubsAuthorizeModalProps({...subsAuthorizeModalProps, open: false});

  const handleCloseDeleteModal = () => setDeleteModalProps({...deleteModalProps, loading: false, open: false});

  const handleOkDeleteModal = (data) => {
    handleSubmitModal({
      action: DELETE,
      authAction: SUBSCRIPTION_DELETE,
      data,
      getter: deleteModalProps,
      setter: setDeleteModalProps,
      logEventName: firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_DELETE,
    });
  }

  const handleOkSubsAuthModal = (data) => {
    handleSubmitModal({
      action: AUTHORIZE,
      authAction: SUBSCRIPTION_SUBMIT,
      data,
      getter: subsAuthorizeModalProps,
      setter: setSubsAuthorizeModalProps,
      logEventName: firebaseEvents.SUBSCRIPTIONS_SUBSCRIPTION_AUTHORIZE,
    });
  }


  const onAction = ({action, successCallback, errorCallback}) => {
    const operationActions = {
      [DELETE]: () => setDeleteModalProps({...deleteModalProps, open: true}),
      [AUTHORIZE]: () => setSubsAuthorizeModalProps({...subsAuthorizeModalProps, open: true}),
    }
    if (operationActions.hasOwnProperty(action)) {
      operationActions[action]();
    } else {
      handleChangeStatus({action, successCallback, errorCallback});
    }
  }

  const headerDetails = (
    <HeaderDetails
      data={subscription}
      handleUpdateData={() => handleUpdateSubscriptionInfo(false, false)}
      onAction={onAction}
    />
  );

  const getTabs = () => {
    const {transactions: transList} = transactions;
    const transactionsCount = gObjProp(subscription, 'transactions_count') || 0;
    const serviceName = subscription?.service?.name;
    return [
      {
        children: (
          <>
            {headerDetails}
            <OverviewTab
              subscription={subscription}
              card={card}
              edit={isEnabledEdit}
              handleUpdateData={handleUpdateSubscriptionInfo}
              transactionsCount={transactionsCount}
            />
          </>
        ),
        key: 'overview',
        label: t('overview')
      },
      {
        children: (
          <>
            {headerDetails}
            <TransactionsTab
              edit={isEnabledEdit}
              subscription={subscription}
              expenses={expenses}
              onExpenseUpdate={onExpenseUpdate}
              transactions={transList}
              loadMoreProps={{
                ...loadMoreProps,
                onClick: handleLoadMore
              }}
              tableProps={{loading: !transactions.isLoaded}}
            />
          </>
        ),
        key: 'transactions',
        label: `${t('transactions')} (${transactionsCount})`
      },
      {
        key: 'usage-report',
        children: (
          <>
            {headerDetails}
            <UsageReportTab
              isActive={activeTab === 'usage-report'}
              service={serviceName}
            />
          </>
        ),
        label: t('subscriptions:usageReport')
      }
    ];
  }

  const handleSetCardHoldDetails = (hold = true, message = '') => setCardHold({hold, message});

  const handleWebsocketMessage = (data) => {
    const gRespProp = (key) => gObjProp(data, key);

    const action = gRespProp('action');
    const subscriptionId = gRespProp('subscription_id');
    // update subscription after receiving card details and close websocket connection
    if ([backendWebsocketActionsConstants.CARD_ACTIVATE, backendWebsocketActionsConstants.CARD_CREATE_ERROR].includes(action) && subscriptionId) {
      setEnabledWebSocketConnection(false);
      getSubscriptionInfo({
        id: subscriptionId,
        isNeedCardLoading: true,
        successCallback: () => handleSetCardHoldDetails(false)
      });
    }
  }

  const handleCloseAuthModal = () => {
    const operationActions = {
      [TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION]: finishTransactionsLoading,
      [TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION]: finishTransactionsLoading,
    }
    setAuthWindowProps({...authWindowProps, open: false});
    if (operationActions.hasOwnProperty(activeAuthAction)) operationActions[activeAuthAction]();
    tempData?.errorCallbackFunction && tempData.errorCallbackFunction();
    finishLoading();
  }

  const handleOnSuccessAuth = () => {
    const operationActions = {
      [TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION]: handleLoadMore,
      [TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION]: getTransactions,
      [SUBSCRIPTION_CHANGE_STATUS_ACTION]: handleChangeStatus,
      [SUBSCRIPTION_DELETE]: handleOkDeleteModal,
      [SUBSCRIPTION_SUBMIT]: handleOkSubsAuthModal
    }
    setAuthWindowProps({...authWindowProps, open: false});
    if (operationActions.hasOwnProperty(activeAuthAction)) operationActions[activeAuthAction]();
  }

  const tabs = getTabs();

  const {isExist, loading} = pageLoading;

  const documentTitle = `${t('pageTitles.subscriptionDetails')} ${pageTitle}`;

  return (
    <PageContainer>
      <PageDocumentDetails title={documentTitle} />
      <BalancePageHeader breadcrumbs={breadcrumbs} />
      <SpinSmall spinning={loading}>
        <StyledSubscriptionPageContainer>

          {isExist ? (
            <StyledTabs
              type='card'
              items={tabs}
              onChange={handleOnTabChange}
            />
          ) : !loading && <NotFoundPage />}

        </StyledSubscriptionPageContainer>
      </SpinSmall>

      {enabledWebSocketConnection && <WebSocket onMessage={handleWebsocketMessage} />}

      <AuthorizeSubscriptionModal
        handleClose={handleCloseSubsAuthModal}
        handleOk={handleOkSubsAuthModal}
        subscription={subscription}
        {...subsAuthorizeModalProps}
      />

      <DeleteModal
        onCancel={handleCloseDeleteModal}
        onOk={handleOkDeleteModal}
        data={subscription}
        {...deleteModalProps}
      />

      <AuthenticationWindow
        {...authWindowProps}
        authModalProps={authModalProps}
        handleCancel={handleCloseAuthModal}
        onSuccess={handleOnSuccessAuth}
        operationName={activeAuthAction}
      />
    </PageContainer>
  );
}

const mapStateToProps = state => {
  const {isAdmin, employee} = state.user;

  return {
    isAdmin,
    employeeId: employee?.id
  }
}


const mapDispatchToProps = {
  clearSubscription: subscriptionActions.clearSubscription,
  deleteSubscription: subscriptionActions.deleteSubscription,
  getBatchExpenses: expenseActions.getBatchExpenses,
  getCardDetails: bankingActions.getUserCardDetails,
  getEmployees: companyActions.getEmployees,
  getSubscription: subscriptionActions.getSubscription,
  getSubscriptionTransactionsList: transactionActions.getSubscriptionTransactionsList,
  getTags: companyActions.getTags,
  getUserDetails: bankingActions.getUserDetails,
  subscriptionQuickAction: subscriptionActions.subscriptionQuickAction,
  setCardHold: subscriptionActions.setCardHold,
  updateCardStatus: bankingActions.updateCardStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
