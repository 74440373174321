import {request} from '../services/request';
import {authConstants, bankingConstants, companyConstants, mainConstants} from '../constants';
import {mainActions} from './main.actions';
import {userActions} from './user.actions';
import {helpers} from '../services/helpers';
import {localStorageKeysConstants} from '../../constants';
import {objectHelpers, scaHelpers} from '../../utils/helpers';
import {backendEndpoints} from '../../api';
import {transactionActions} from './transaction.actions';

const {COMPANY_ID, SESSION_ID, SHOW_WELCOME} = localStorageKeysConstants;

const errorHandlerProps = {disableAlert: true};

const login = (data, success, error) => {
  return (dispatch) => {
    request.post({
      dispatch,
      url: backendEndpoints.POST_AUTH_LOGIN,
      data,
      successCallback: (response) => {
        dispatch({
          type: authConstants.SUCCESS_LOGIN,
          data: response.session,
          phoneNumber: data.phone_number
        });
        if (success) success(response);
      },
      errorCallback: (err) => {
        let data = {message: 'Something went wrong, try one more time'};
        if (err && err.hasOwnProperty('response') && err.response !== undefined) {
          data = err.response.data;
          if (error) error(data);
        }
      },
      errorHandlerProps,
      skipAuthRefresh: true
    });
  }
}

const codeVerification = (data, success, error) => {
  return (dispatch) => {
    request.post({
      dispatch,
      url: backendEndpoints.POST_AUTH_CODE_VERIFICATION,
      data,
      successCallback: (data) => {
        let hasCompanies, showWelcome;
        let companies = data.user_companies || [];
        let isCreationRequired = data.is_company_creation_required;
        if (objectHelpers.isNaV(isCreationRequired)) isCreationRequired = true;
        companies = companies.filter(c => c.id !== null && c.is_enabled);
        hasCompanies = companies.length > 0;
        showWelcome = isCreationRequired && !hasCompanies;
        dispatch({
          type: authConstants.SUCCESS_VERIFY_CODE,
          data,
          showWelcome
        });
        helpers.saveJWT(data);

        if (showWelcome) localStorage.setItem(SHOW_WELCOME, 'true');

        if (hasCompanies) {
          const {id} = companies[0];
          if (id) localStorage.setItem(COMPANY_ID, id);
        }

        success && success(data);
      },
      errorCallback: (err) => {
        const {data} = err.response;
        error && error(data);
      },
      errorHandlerProps
    });
  }
}

const signUpPhoneCodeVerification = (data, success, error) => {
  return simpleRequestTemplate(
    data,
    backendEndpoints.POST_AUTH_SIGNUP_CODE_VERIFICATION,
    'PHONE_VERIFY_CODE',
    success,
    error,
    false
  );
}


const signUpCodeResend = (data, success, error) => {
  return simpleRequestTemplate(
    data,
    backendEndpoints.POST_AUTH_SIGNUP_CODE_RESEND,
    'CODE_RESEND',
    success,
    error,
    false
  );
}

const phoneVerification = (data, success, error) => {
  return simpleRequestTemplate(
    data,
    backendEndpoints.POST_AUTH_SIGNUP_PHONE_VERIFICATION,
    'PHONE_VERIFICATION',
    (data) => {
      localStorage.setItem(SESSION_ID, data.session_id);
      success && success(data);
    },
    error,
    false
  );
}

const createPassword = (data, success, error) => {
  return simpleRequestTemplate(
    data,
    backendEndpoints.POST_AUTH_SIGNUP,
    'CREATE_PASSWORD',
    (data) => {
      helpers.saveJWT(data);
      success && success(data);
    },
    error,
    false
  );
}

const finishRegistration = (data, success, error) => {
  return (dispatch, getState) => {
    const {companyInviteToken} = getState().auth;
    request.post({
      dispatch,
      url: backendEndpoints.POST_AUTH_SIGNUP_FINISH,
      data,
      successCallback: (data) => {
        let showWelcome;
        let isFirstCompanyCreated = data.is_first_company_created;
        if (objectHelpers.isNaV(isFirstCompanyCreated)) isFirstCompanyCreated = false;
        showWelcome = !isFirstCompanyCreated && companyInviteToken === null;
        let companies = data.user_companies || [];
        dispatch({
          type: authConstants.SUCCESS_FINISH_REGISTRATION,
          data,
          showWelcome
        });
        localStorage.removeItem(SESSION_ID);

        if (companies.length > 0) localStorage.setItem(COMPANY_ID, companies[0].id);

        if (showWelcome) {
          localStorage.setItem(SHOW_WELCOME, 'true');
        } else if (!showWelcome && localStorage.getItem(SHOW_WELCOME)) {
          localStorage.removeItem(SHOW_WELCOME);
        }

        if (success) success();
      },
      errorCallback: (err) => {
        const {data} = err.response;
        error && error(data);
        dispatch({
          type: authConstants.ERROR_FINISH_REGISTRATION
        });
      }
    });
  }
}

const companyInviteFinish = (data, success, error) => {
  return simpleRequestTemplate(
    data,
    backendEndpoints.POST_AUTH_SIGNUP_FINISH,
    'COMPANY_INVITE_FINISH',
    success,
    error
  );
}

const resetPassword = (data, success, error) => {
  return simpleRequestTemplate(
    data,
    backendEndpoints.POST_RESET_PASSWORD,
    undefined,
    success,
    error,
    false
  );
}

const confirmPasswordResetting = (data, success, error) => {
  return simpleRequestTemplate(
    data,
    backendEndpoints.POST_CONFIRM_RESET_PASSWORD,
    undefined,
    success,
    error,
    false
  );
}

const simpleRequestTemplate = (data, url, dispatchTypeName, success, error, showLoader = true) => {
  return (dispatch) => {
    const loading = (loadingStatus) => showLoader && mainActions.setLoading(loadingStatus, dispatch);
    loading(true);
    request.post({
      dispatch,
      url,
      data,
      successCallback: (data) => {
        if (dispatchTypeName) {
          dispatch({
            type: authConstants[`SUCCESS_${dispatchTypeName}`],
            data
          });
        }
        loading(false);
        if (success) success(data);
      },
      errorCallback: (err) => {
        const {data} = err.response;
        if (error) error(data);
        loading(false);
        if (dispatchTypeName) {
          dispatch({
            type: authConstants[`ERROR_${dispatchTypeName}`],
            data
          });
        }
      },
      errorHandlerProps
    });
  }
}

const getInvitationDetails = (token, success, error) => {
  return (dispatch) => {
    const loading = (loadingStatus) => mainActions.setInitialLoading(loadingStatus, dispatch);
    loading(true);
    try {
      const data = helpers.parseJWT(token);
      loading(false);
      dispatch({
        type: authConstants.GET_INVITATION_DETAILS,
        data
      });
      if (success) success();
      loading(false);
    } catch (err) {
      const {response} = err;
      const data = response && response.hasOwnProperty('data') ? response.data : {};
      if (error) error(data);
      loading(false);
    }
  }
}

const setEmailVerificationToken = (token, isInvitation = false) => {
  return (dispatch) => {
    dispatch({
      type: authConstants.SET_EMAIL_VERIFICATION_TOKEN,
      data: token,
      isInvitation
    });
    mainActions.setInitialLoading(false, dispatch);
  }
}

const setCompanyInviteToken = (token) => {
  return (dispatch) => {
    dispatch({
      type: authConstants.SET_COMPANY_INVITE_TOKEN,
      data: token
    });
  }
}

const showCompanyInviteRegister = (show = false) => {
  return (dispatch) => {
    dispatch({
      type: authConstants.SET_SHOW_COMPANY_INVITE_REGISTER,
      data: show,
    });
  }
}

const logout = () => {
  helpers.clearUserStoredData();
  scaHelpers.clearStoredData();
  return (dispatch) => {
    dispatch({ type: authConstants.LOGOUT });
    dispatch({ type: companyConstants.CLEAR_COMPANY_DATA });
    dispatch({ type: mainConstants.CLEAR_MAIN_DATA });
    dispatch(userActions.authUser(false));
    dispatch(userActions.clearUserData());
    dispatch({ type: bankingConstants.CLEAR_CARDS_DATA });
    dispatch(transactionActions.clearBankingDetails());
  }
}

const storeUboInviteKey = (token) => {
  return (dispatch) => {
    dispatch({
      type: authConstants.SET_UBO_INVITE_KEY,
      data: token
    });
  }
}

export const authActions = {
  login,
  logout,
  codeVerification,
  createPassword,
  finishRegistration,
  phoneVerification,
  signUpPhoneCodeVerification,
  signUpCodeResend,
  getInvitationDetails,
  setEmailVerificationToken,
  setCompanyInviteToken,
  companyInviteFinish,
  showCompanyInviteRegister,
  storeUboInviteKey,
  resetPassword,
  confirmPasswordResetting
};
