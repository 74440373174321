import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Bar, BarChart as BChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {CustomizedAxisTick} from '../SubscriptionChart/SubscriptionChart';
import SubscriptionTooltip from '../SubscriptionTooltip';
import {amountHelpers, subscriptionsHelpers, textHelpers} from '../../../../utils/helpers';

const axisProps = {
  axisLine: true,
  fontSize: 14,
  stroke: '#9CA1AA',
  strokeWidth: 0.5,
  strokeDasharray: '5 5',
  tickLine: false
};

const CustomizedBarTick = (props) => {
  const { index, data, onClick, x, y, payload, style, ...rest } = props;

  if (payload.value) {
    return (
      <text
        className={rest.className}
        dy={rest.dy}
        fill={rest.fill}
        fontSize={rest.fontSize}
        stroke={rest.stroke}
        style={style}
        textAnchor={rest.textAnchor}
        y={y}
        x={x}
        width={rest.width}
      >
        {payload.value}
      </text>
    )
  } else {
    const radius = 13;
    const circleId = `view-${index}`;
    const dataItem = data && data.length ? data[index] : undefined;
    if (dataItem === undefined) return ;
    const logo = dataItem?.service?.logo;
    const name = dataItem?.service?.name;

    if (logo) {
      return (
        <g style={style}>
          <defs>
            <clipPath id={circleId}>
              <circle cx={x + radius} cy={y + radius} r={radius}/>
            </clipPath>
          </defs>
          <image
            x={x} y={y}
            xlinkHref={logo}
            height={radius * 2} width={radius * 2}
            fill="#666"
            clipPath={`url(#${circleId})`}
            style={{transform: `translateX(-${radius}px)`}}
          />
        </g>
      );
    } else {
      return (
        <g textAnchor='middle' style={style}>
          <circle
            cx={x + radius}
            cy={y + radius}
            r={radius}
            fill='rgba(39, 37, 53, 0.8)'
            style={{transform: `translateX(-${radius}px)`}}
          />
          <text
            fill='#fff'
            fontSize={12}
            x={x + radius}
            y={y + radius}
            width="15"
            style={{transform: `translate(-${radius}px, 4px)`}}>
            {textHelpers.getInitials(name)}
          </text>
        </g>
      );
    }
  }
};

const defaultTooltipProps = {
  actualSpend: 0,
  expectedSpend: 0,
  services: []
};

const SpendingBarChart = ({
  barRadius,
  barWidth,
  data,
  height,
  isAnimationActive,
  isEnabledTooltip,
  onBarClick,
  width,
  ...rest
}) => {
  const [tooltipProps, setTooltipProps] = useState(defaultTooltipProps);
  const [activeTooltipIndex, setActiveTooltipIndex] = useState(null);

  const styleProps = onBarClick ? { cursor: 'pointer' } : undefined

  const barProps = {
    barSize: barWidth,
    style: styleProps
  }

  const handleOnMouseMove = (props) => {
    if (props) {
      const {activeTooltipIndex: actTooltipIndex, activePayload, isTooltipActive} = props;
      if (activePayload === undefined || activeTooltipIndex === actTooltipIndex || !isTooltipActive) return;
      setActiveTooltipIndex(actTooltipIndex);
      const payloadObj = activePayload && activePayload.length ? activePayload[0] : undefined

      const actualSpend = payloadObj?.payload?.actual || 0;
      const expectedSpend = payloadObj?.payload?.expected || 0;
      const servicesData = payloadObj ? [payloadObj.payload] : [];
      const services = ['actual', 'expected'].map(name => ({
        name,
        data: servicesData.filter(s => s.service).map(d => ({...d, value: d[name]}))
      }));
      setTooltipProps({
        ...tooltipProps,
        actualSpend,
        expectedSpend,
        services: subscriptionsHelpers.calculateTooltipServices(services)
      });
    }
  }

  const handleOnXAsisClick = (props, _, e) => {
    const payload = data[props.index];
    onBarClick && onBarClick(e, payload);
  }

  const handleOnBarClick = (payload, _, e) => onBarClick && onBarClick(e, payload);

  const getPrice = (value) => amountHelpers.getAmountWithCurrencyCode(value, undefined, undefined, true, false);

  return (
    <div style={{ height }} {...rest}>
      <ResponsiveContainer height={height} width='100%'>
        <BChart
          width={width}
          height={height}
          data={data}
          onMouseMove={handleOnMouseMove}
        >
          <defs>
            <linearGradient id='colorActual' gradientUnits='userSpaceOnUse' x1='47.66%' y1='102.14%' x2='52.34%' y2='-2.14%'>
              <stop offset='.019' stopColor='#40B0D5'/>
              <stop offset='.976' stopColor='#005F7F'/>
            </linearGradient>
            <linearGradient id='colorExpected' x1='.5' x2='.5' y2='1'>
              <stop stopColor='#E3E3E3'/>
              <stop offset='1' stopColor='#E9E9E9'/>
            </linearGradient>
          </defs>
          <XAxis
            {...axisProps}
            dataKey='label'
            dy={12}
            onClick={handleOnXAsisClick}
            tick={(props) => <CustomizedBarTick {...props} data={data} style={styleProps} />}
          />
          <YAxis
            {...axisProps}
            tick={<CustomizedAxisTick getPriceValue={getPrice} />}
          />
          <CartesianGrid
            {...axisProps}
            vertical={false}
          />
          <Bar
            {...barProps}
            dataKey='actual'
            fill='url(#colorActual)'
            onClick={handleOnBarClick}
          />
          <Bar
            {...barProps}
            dataKey='expected'
            fill='url(#colorExpected)'
            onClick={handleOnBarClick}
          />
          <Tooltip
            animationEasing='ease'
            cursor={{fill: 'transparent'}}
            content={<SubscriptionTooltip {...tooltipProps} />}
            offset={0}
          />
        </BChart>
      </ResponsiveContainer>
    </div>
  );
}

SpendingBarChart.propTypes = {
  barWidth: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      actual: PropTypes.number.isRequired,
      expected: PropTypes.number.isRequired,
      label: PropTypes.string
    })
  ),
  height: PropTypes.number,
  isAnimationActive: PropTypes.bool,
  isEnabledTooltip: PropTypes.bool,
  onBarClick: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

SpendingBarChart.defaultProps = {
  barRadius: 4,
  barWidth: 24,
  data: [],
  isAnimationActive: true,
  isEnabledTooltip: false,
  height: 300,
  width: 500
}

export default SpendingBarChart;
