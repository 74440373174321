import {mainConstants} from '../constants';
import {request} from '../services/request';
import {backendEndpoints} from '../../api';

const errorHandlerProps = {disableAlert: true};

const setLoading = (loading, dispatch) => {
  return dispatch({
    type: mainConstants.SET_LOADING,
    loading
  })
}

const setInitialLoading = (loading, dispatch) => {
  return dispatch({
    type: mainConstants.SET_INITIAL_LOADING,
    loading
  })
}

const setNavigationLoading = (loading) => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.SET_NAVIGATION_LOADING,
      loading
    })
  }
}

const getCurrencies = () => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_CURRENCIES,
      successCallback: (data) => {
        dispatch({
          type: mainConstants.GET_CURRENCIES,
          data: data.sort()
        });
      }
    });
  }
}

const getPermissionMatrix = () => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_PERMISSION_MATRIX,
      successCallback: (data) => {
        dispatch({
          type: mainConstants.GET_PERMISSION_MATRIX,
          data
        });
      },
      errorHandlerProps
    });
  }
}

const collapseNavigationMenu = () => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.COLLAPSE_NAVIGATION_MENU,
    })
  }
}

const openKycWindow = ({mode, closeIcon = true}) => {
  return (dispatch) => {
    dispatch({
      data: {closeIcon, mode},
      type: mainConstants.OPEN_KYC_WINDOW,
    })
  }
}

const closeKycWindow = () => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.CLOSE_KYC_WINDOW,
    })
  }
}

const openLightKycWindow = () => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.OPEN_LIGHT_KYC_WINDOW,
    })
  }
}

const closeLightKycWindow = () => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.CLOSE_LIGHT_KYC_WINDOW,
    })
  }
}

const setAvailableModules = (modules = []) => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.SET_AVAILABLE_MODULES,
      data: {modules}
    })
  }
}

const setNavigationMenuProps = (props) => {
  return (dispatch) => {
    dispatch({
      type: mainConstants.SET_NAVIGATION_MENU_PROPS,
      navigationMenuProps: props
    })
  }
}


export const mainActions = {
  collapseNavigationMenu,
  setLoading,
  setInitialLoading,
  getCurrencies,
  getPermissionMatrix,
  closeKycWindow,
  closeLightKycWindow,
  openKycWindow,
  openLightKycWindow,
  setAvailableModules,
  setNavigationLoading,
  setNavigationMenuProps
};
