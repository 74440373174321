import React, {useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Transition} from 'react-transition-group';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import SpinSmall from '../../components/SpinSmall';
import NotFoundPage from '../NotFoundPage';
import {
  alertActions,
  bankingActions,
  cardActions,
  companyActions,
  expenseActions,
  invoicesActions,
  subscriptionActions,
  transactionActions
} from '../../state/actions';
import {
  StyledCardPageContainer,
  StyledCardPageContainerSpace,
  StyledCardPageTransactionContainer
} from './StyledCardPage';
import PageContainer from '../../components/PageContainer';
import BalancePageHeader from '../../components/pages/TransactionsPage/BalancePageHeader/BalancePageHeader';
import AuthenticationWindow from '../../components/pages/CardsPage/AuthenticationWindow';
import CardDetailsPanel from '../../components/pages/CardPage/CardDetailsPanel';
import DeleteCardModal from '../../components/pages/CardPage/DeleteCardModal';
import TransactionsPanel from '../../components/pages/CardPage/TransactionsPanel';
import TransactionDetailsPanel from '../../components/pages/TransactionsPage/TransactionDetailsPanel';
import TransactionDetails from '../../components/pages/TransactionsPage/TransactionDetails/TransactionDetails';
import AddInvoiceModal from '../../components/pages/TransactionsPage/AddInvoiceModal/AddInvoiceModal';
import routes from '../../routes/routes.json';
import {
  cardsHelpers,
  objectHelpers,
  scaHelpers,
  systemHelpers,
  transactionsHelpers
} from '../../utils/helpers';
import {
  cardStatusesConstants,
  SCAActionsConstants,
  subscriptionFormFields,
  transactionsRequestLimit
} from '../../constants';
import {firebaseEvents} from '../../snippets/firebase';
import {useHasAccess, useIsEmployeeLoaded, useIsEnabledUserOverview} from '../../hooks';

const duration = 400;

const detailsTransitionStyles = {
  default: {
    opacity: 1,
    transition: `${duration}ms ease-in-out`
  },
  entering: {transform: 'translateX(0)', opacity: 0.75, width: 448},
  entered: {transform: 'translateX(0)', opacity: 1, width: 448},
  exiting: {transform: 'translateX(200%)', opacity: 0.25, width: 0, visibility: 'hidden'},
  exited: {transform: 'translateX(200%)', opacity: 0, width: 0, visibility: 'hidden'}
}

const {
  cardLimitFieldName,
  cardLimitPeriodFieldName,
  limitPeriodShortFieldName
} = subscriptionFormFields;

const cardFields = [cardLimitFieldName, limitPeriodShortFieldName];

const {
  CARD_DELETE_ACTION,
  CARD_EDITION_ACTION,
  CARD_PAUSE_ACTION,
  TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION,
  TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION
} = SCAActionsConstants;

const {getAuthHeaders} = scaHelpers;

const {logEvent} = systemHelpers;

const {isValidValue} = objectHelpers;

const CardPage = ({
  addInvoiceModalProps,
  batchCreateInvoices,
  closeAddInvoiceModal,
  deleteCard,
  getBatchExpenses,
  getBankingCardDetails,
  getMainCardDetails,
  getSubscription,
  getTransactionsList,
  getTags,
  getUserDetails,
  pauseCard,
  updateCard,
  updateCardLimits,
  linkExpense,
  unlinkExpense,
  openAddInvoiceModal,

  employees,
  employeeId,
  isAdmin,
}) => {
  const uriParams = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation(['main', 'subscriptions', 'cards']);
  const [cardDetails, setCardDetails] = useState({
    bankingData: null,
    mainData: null
  });
  const [pageLoading, setPageLoading] = useState({loading: true, isExist: true});
  const [transactions, setTransactions] = useState({transactions: [], isLoaded: false});
  const [transactionsWithExpense, setTransactionsWithExpense] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [activeAuthAction, setActiveAuthAction] = useState(null);
  const [loadMoreProps, setLoadMoreProps] = useState({cursor: null, loading: false, isEnabled: false});
  const [deleteModalProps, setDeleteModalProps] = useState({loading: false, open: false});
  const [tempData, setTempData] = useState({
    callbackFunction: undefined,
    errorCallbackFunction: undefined,
    successCallbackFunction: undefined,
    data: undefined
  });
  const [isOpenDetails, setIsOpenDetails] = useState({card: true, transaction: false});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [selectedTransactionRowIndex, setSelectedTransactionRowIndex] = useState(undefined);
  const [isExistBankingUser, setIsExistBankingUser] = useState(true);

  const hasEditAccess = useHasAccess('PUT_UPDATE_CARD');

  const cardDetailsNodeRef = useRef(null);
  const transactionDetailsNodeRef = useRef(null);

  const {cardId, userId} = useMemo(() => ({
    cardId: uriParams?.cardId,
    userId: uriParams?.userId
  }), [uriParams]);

  const isEnabledEdit = useMemo(() => hasEditAccess || employeeId === userId, [hasEditAccess, employeeId, userId]);

  const cardDetailsPanel = useMemo(() => ({
    bankingData: cardDetails.bankingData,
    cardData: cardDetails.mainData,
    loading: pageLoading.loading
  }), [pageLoading, cardDetails]);

  const cardNumber = useMemo(() => cardsHelpers.hideCardNumber(cardDetails?.mainData?.masked_pan, 6), [cardDetails]);

  const cardHold = useMemo(() => {
    let hold = false;
    if (pageLoading.isExist && !pageLoading.loading) {
      hold = cardDetails?.bankingData?.status_code === undefined;
    }
    if (!isExistBankingUser) hold = true;
    return hold;
  }, [cardDetails, isExistBankingUser, pageLoading]);

  const isEnabledUserOverview = useIsEnabledUserOverview();

  const {breadcrumbs, documentTitle} = useMemo(() => {
    let title = '';
    const cardHoldMessage = t('cards:errors.hold');
    const displayedCardName = cardNumber || cardDetails?.mainData?.name;
    if (!pageLoading.isExist && !pageLoading.loading) {
      title = t('elementNotFound', {name: t('cards:card')});
    } else if (displayedCardName) {
      title = `${displayedCardName} ${t('detail')}`;
    } else if (cardHold) {
      title = cardHoldMessage;
    }
    const defaultBreadcrumbs = [
      {title: t('navigation.cards'), href: routes.cardsList},
      {title}
    ];
    return {
      breadcrumbs: systemHelpers.getUserBreadcrumbs({defaultBreadcrumbs, isAdmin, isEnabledUserOverview, t}),
      documentTitle: cardHoldMessage !== title ? `${t('pageTitles.cardDetails')} ${title}` : cardHoldMessage,
    }
  }, [t, cardDetails?.mainData, cardHold, cardNumber, pageLoading, isAdmin, isEnabledUserOverview]);

  const authModalProps = useMemo(() => scaHelpers.getAuthModalProps({action: activeAuthAction, t}), [activeAuthAction, t]);

  const finishLoading = (isExist = true) => setPageLoading({...pageLoading, isExist, loading: false});

  const isEmployeeLoaded = useIsEmployeeLoaded();

  useEffect(() => {
    if (!isValidValue(cardId) || !isValidValue(userId)) return;

    setPageLoading({...pageLoading, loading: true});
    setTransactions({...transactions, isLoaded: false});

    // get information about card details from backend
    getMainCardDetails(
      cardId,
      (mainData) => {
        const subscriptionId = mainData?.subscription_id;
        // get information about card subscription
        if (subscriptionId) {
          getSubscription(
            subscriptionId,
            (subscription) => setSubscription(subscription)
          );
        }
        // get information about card details from banking
        getBankingCardDetails(
          userId,
          cardId,
          (bankingData) => {
            setCardDetails({
              ...cardDetails,
              bankingData,
              mainData
            });
            getTransactions(bankingData);
            finishLoading();
          },
          () => {
            setCardDetails({
              ...cardDetails,
              mainData
            });
            finishLoading();
            setTransactions({...transactions, isLoaded: true});
          }
        );

        // check whether the cardholder is registered with the bank
        if (isAdmin) {
          getUserDetails(
            userId,
            () => setIsExistBankingUser(true),
            () => setIsExistBankingUser(false)
          );
        }
      },
      () => finishLoading(false)
    );
  }, [cardId, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEmployeeLoaded) {
      getTags();
    }
  }, [isEmployeeLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const transactionsWithExpense = transactionsHelpers.getTransactionsWithExpense({
      expenses,
      employees,
      transactions: transactions.transactions
    });
    setTransactionsWithExpense(transactionsWithExpense);
  }, [transactions, expenses, employees]);

  const SCAErrorCallback = ({response, successCallback, errorCallback}) => {
    scaHelpers.SCAResponseCallback({
      response,
      scaCallback: (scaProps) => {
        setAuthWindowProps({...authWindowProps, ...scaProps});
        successCallback && successCallback();
      },
      errorCallback
    });
  }

  const getTransactions = (card) => {
    loadTransactionsList({
      card: card || cardDetails?.bankingData,
      operationName: TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION,
      query: {},
      successCallback: (response) => getTransactionsSuccessCallback(response, true),
      errorCallback: () => setTransactions({...transactions, isLoaded: true, transactions: []})
    });
  }

  const handleCloseAuthModal = () => {
    const operationActions = {
      [CARD_DELETE_ACTION]: () => setDeleteModalProps({...deleteModalProps, loading: false}),
      [CARD_EDITION_ACTION]: () => tempData && tempData.errorCallbackFunction && tempData.errorCallbackFunction(),
      [CARD_PAUSE_ACTION]: () => tempData && tempData.callbackFunction && tempData.callbackFunction(),
      [TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION]: finishLoading,
      [TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION]: finishLoading,
    }
    setAuthWindowProps({...authWindowProps, open: false});
    if (operationActions.hasOwnProperty(activeAuthAction)) operationActions[activeAuthAction]();
  }

  const handleOnSuccessAuth = () => {
    const operationActions = {
      [CARD_DELETE_ACTION]: handleDeleteCard,
      [CARD_EDITION_ACTION]: handleEditCard,
      [CARD_PAUSE_ACTION]: handlePauseCard,
      [TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION]: handleLoadMore,
      [TRANSACTIONS_WITHIN_90_DAYS_LOADING_ACTION]: getTransactions,
    }
    setAuthWindowProps({...authWindowProps, open: false});
    if (operationActions.hasOwnProperty(activeAuthAction)) operationActions[activeAuthAction]();
  }

  const getTransactionsSuccessCallback = (response, initial = false) => {
    const responseTransactions = response?.results || [];
    const cursor = response?.pagination?.next_cursor || undefined;
    const expensesIds = responseTransactions.map(t => t.expense_id).filter(value => value);

    setTransactions({
      transactions: initial ? responseTransactions : [...transactions.transactions, ...responseTransactions],
      isLoaded: true
    });

    if (Boolean(expensesIds.length)) {
      getBatchExpenses(
        {expenses_ids: expensesIds},
        (responseExpenses) => setExpenses(initial ? responseExpenses : [...expenses, ...responseExpenses])
      );
    }

    setLoadMoreProps({
      ...loadMoreProps,
      cursor,
      loading: false,
      isEnabled: Boolean(cursor),
    });
  }

  const loadTransactionsList = ({
    query,
    successCallback,
    errorCallback,
    operationName
  }) => {
    const requestQuery = {
      sort_order: 'desc',
      limit: transactionsRequestLimit,
      ...query
    }

    if (operationName && operationName !== activeAuthAction) setActiveAuthAction(operationName);

    getTransactionsList({
      cardId,
      userId,
      headers: scaHelpers.getAuthHeaders(operationName || activeAuthAction),
      query: requestQuery,
      successCallback,
      errorCallback: (response) => {
        scaHelpers.SCAResponseCallback({
          response,
          scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
          errorCallback
        });
      }
    });
  }

  const handleLoadMore = () => {
    if (loadMoreProps.isEnabled) {
      const cursor = loadMoreProps?.cursor;
      setLoadMoreProps({...loadMoreProps, loading: true});

      loadTransactionsList({
        operationName: TRANSACTIONS_OLDER_90_DAYS_LOADING_ACTION,
        query: { cursor },
        successCallback: getTransactionsSuccessCallback,
        errorCallback: () => setLoadMoreProps({...loadMoreProps, loading: false})
      });
    }
  }

  const handleDeleteCard = () => {
    setDeleteModalProps({...deleteModalProps, loading: true});
    deleteCard({
      id: cardId,
      headers: getAuthHeaders(CARD_DELETE_ACTION),
      successCallback: () => {
        handleCloseAuthModal();
        alertActions.success(t('cards:successCardDeleteMessage'));
        logEvent(firebaseEvents.CARDS_DELETE_CARD);
        navigate(routes.cardsList);
      },
      errorCallback: (response) => {
        SCAErrorCallback({
          response,
          errorCallback: () => setDeleteModalProps({...deleteModalProps, open: true, loading: false})
        });
      }
    })
  }

  const handleOkDeleteModal = () => {
    setActiveAuthAction(CARD_DELETE_ACTION);
    handleDeleteCard();
  }

  const handleCancelDeleteModal = () => setDeleteModalProps({...deleteModalProps, loading: false, open: false});

  const onDelete = () => setDeleteModalProps({...deleteModalProps, loading: false, open: true});

  const onPause = (checked, callbackFunction) => {
    setTempData({...tempData, callbackFunction});
    setActiveAuthAction(CARD_PAUSE_ACTION);
    handlePauseCard(callbackFunction);
  }

  const handlePauseCard = (callbackFunction) => {
    const callBackFunction = callbackFunction || tempData.callbackFunction;
    pauseCard({
      id: cardId,
      headers: getAuthHeaders(CARD_PAUSE_ACTION),
      successCallback: (data) => {
        const status = data?.lock_status;
        const pauseLogEvents = {
          [cardStatusesConstants.ACTIVE]: firebaseEvents.CARDS_ACTIVE_CARD,
          [cardStatusesConstants.FROZEN]: firebaseEvents.CARDS_DEACTIVATE_CARD,
        };
        const pauseLogEvent = pauseLogEvents[status];
        setCardDetails({
          ...cardDetails,
          bankingData: {...cardDetails.bankingData, status_code: status},
          mainData: {...cardDetails.mainData, lock_status: status}
        });
        callBackFunction && callBackFunction();
        pauseLogEvent && logEvent(pauseLogEvent);
      },
      errorCallback: (response) => {
        SCAErrorCallback({
          response,
          errorCallback: callBackFunction
        });
      }
    })
  }

  const onFormSubmit = ({data, errorCallback, successCallback}) => {
    setTempData({
      ...tempData,
      data,
      errorCallbackFunction: errorCallback,
      successCallbackFunction: successCallback
    });
    setActiveAuthAction(CARD_EDITION_ACTION);
    handleEditCard({data, errorCallback, successCallback});
  }

  const handleEditCard = ({data, errorCallback, successCallback} = {}) => {
    const formData = data || tempData.data;
    const successCallbackFunction = successCallback || tempData.successCallbackFunction;
    const errorCallbackFunction = errorCallback || tempData.errorCallbackFunction;

    // check if need to change card limits
    let isRequiredUpdateCardLimits = false;
    cardFields.forEach(field => {
      if (cardDetails?.mainData[field] !== formData[field]) isRequiredUpdateCardLimits = true;
    });

    // update card details
    const updateMainCardData = (cardBankingData) => {
      updateCard({
        data: formData,
        id: cardId,
        headers: getAuthHeaders(CARD_EDITION_ACTION),
        successCallback: (updatedCardMainData) => {
          let updatedCardDetails = {
            ...cardDetails,
            mainData: updatedCardMainData
          };
          if (cardBankingData) updatedCardDetails = {...updatedCardDetails, bankingData: cardBankingData};
          successCallbackFunction && successCallbackFunction();
          setCardDetails(updatedCardDetails);
          logEvent(firebaseEvents.CARDS_EDIT_CARD_DETAILS);
        },
        errorCallback: (response) => {
          scaHelpers.SCAResponseCallback({
            response,
            scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
            errorCallback: errorCallbackFunction
          });
        }
      })
    }

    if (isRequiredUpdateCardLimits) {
      const formLimitsData = cardsHelpers.getSetLimitPayload({
        ...formData,
        [cardLimitPeriodFieldName]: formData[limitPeriodShortFieldName]
      });
      // update card limits
      updateCardLimits({
        cardId,
        userId,
        data: formLimitsData,
        headers: getAuthHeaders(CARD_EDITION_ACTION),
        successCallback: (response) => {
          updateMainCardData(response);
          logEvent(firebaseEvents.CARDS_EDIT_CARD_LIMITS);
        },
        errorCallback: (response) => {
          SCAErrorCallback({
            response,
            errorCallback: errorCallbackFunction
          });
        }
      });
    } else {
      updateMainCardData();
    }
  }

  const handleCloseTransactionDetails = () => {
    setIsOpenDetails({card: true, transaction: false});
    selectedTransactionRowIndex && setSelectedTransactionRowIndex(undefined);
  }

  const handleOnRowClick = (transactionId, index) => {
    let transaction = transactionsWithExpense.find(d => d.id === transactionId);
    if (transaction) {
      setSelectedTransaction(transaction);
      setIsOpenDetails({card: false, transaction: true});
      logEvent(firebaseEvents.CARDS_OPEN_TRANSACTION_DETAILS);
    }
    selectedTransactionRowIndex !== index && setSelectedTransactionRowIndex(index);
  }

  const onExpenseUpdate = (updatedExpense, actionType) => {
    if (updatedExpense) {
      let updatedExpenses = transactionsHelpers.getUpdatedBatchExpensesList(expenses, updatedExpense, actionType);
      setExpenses(updatedExpenses);
      if (selectedTransaction) {
        setSelectedTransaction({
          ...selectedTransaction,
          expense: {
            ...selectedTransaction.expense,
            ...updatedExpense
          }
        })
      }
    }
  }

  const successChangeInvoiceHandle = (response, successCallback) => {
    successCallback && successCallback(response);
    logEvent(firebaseEvents.CARDS_UPDATE_TRANSACTION_INVOICE);
  }

  const handleOkAttachmentModal = (formData, successCallback, errorCallback) => {
    transactionsHelpers.addInvoiceToTransaction({
      addInvoiceModalProps,
      formData,
      successCallback: (response) => successChangeInvoiceHandle(response, successCallback),
      errorCallback,
      batchCreateInvoices,
      closeAddInvoiceModal,
      linkExpense,
      onExpenseUpdate
    });
  }

  const handleInvoiceRemove = (successCallback, errorCallback) => {
    transactionsHelpers.removeInvoiceFromTransaction({
      t,
      transaction: selectedTransaction,
      successCallback: (response) => successChangeInvoiceHandle(response, successCallback),
      errorCallback,
      unlinkExpense,
      onExpenseUpdate
    });
  }

  const {isExist, loading} = pageLoading;

  return (
    <PageContainer>
      <PageDocumentDetails title={documentTitle} />
      <BalancePageHeader breadcrumbs={breadcrumbs} />
      <SpinSmall spinning={loading}>
        <StyledCardPageContainer>

          {isExist ? (
            <StyledCardPageContainerSpace
              align='start'
              size='large'
            >
              <TransactionsPanel
                edit={isEnabledEdit}
                expenses={expenses}
                loading={!transactions.isLoaded}
                loadMoreProps={{
                  ...loadMoreProps,
                  onClick: handleLoadMore
                }}
                transactions={transactionsWithExpense}
                onInvoiceEdit={openAddInvoiceModal}
                onRowClick={handleOnRowClick}
                selectedRowIndex={selectedTransactionRowIndex}
              />
              <StyledCardPageTransactionContainer>
                <Transition
                  nodeRef={cardDetailsNodeRef}
                  in={isOpenDetails.card}
                  timeout={duration}
                >
                  {state => (
                    <CardDetailsPanel
                      style={{
                        ...detailsTransitionStyles.default,
                        ...detailsTransitionStyles[state]
                      }}
                      cardHold={cardHold}
                      cardHoldMessage={!isExistBankingUser && t('cards:errors.theUserNeedToFinishLightKYC')}
                      cardDetails={cardDetailsPanel}
                      onPause={onPause}
                      onDelete={onDelete}
                      onFormSubmit={onFormSubmit}
                      subscription={subscription}
                    />
                  )}
                </Transition>
                <Transition
                  nodeRef={transactionDetailsNodeRef}
                  in={isOpenDetails.transaction}
                  timeout={duration}
                >
                  {state => (
                    <TransactionDetailsPanel
                      backButtonText={t('back')}
                      className='transaction-details-panel'
                      contentClassName='transaction-details-panel-content'
                      onBack={handleCloseTransactionDetails}
                      style={{
                        ...detailsTransitionStyles.default,
                        ...detailsTransitionStyles[state]
                      }}
                    >
                      <TransactionDetails
                        availableLinks={['subscription']}
                        edit={isEnabledEdit}
                        onExpenseUpdate={onExpenseUpdate}
                        onInvoiceEdit={openAddInvoiceModal}
                        onInvoiceRemove={handleInvoiceRemove}
                        subscription={subscription}
                        transaction={selectedTransaction}
                      />
                    </TransactionDetailsPanel>
                  )}
                </Transition>
              </StyledCardPageTransactionContainer>
            </StyledCardPageContainerSpace>
          ) : !loading && <NotFoundPage />}

        </StyledCardPageContainer>
      </SpinSmall>

      <DeleteCardModal
        {...deleteModalProps}
        cardNumber={cardNumber}
        onOk={handleOkDeleteModal}
        onCancel={handleCancelDeleteModal}
      />

      <AddInvoiceModal
        onCancel={closeAddInvoiceModal}
        open={addInvoiceModalProps.open}
        transaction={addInvoiceModalProps.transaction}
        onOk={handleOkAttachmentModal}
      />

      <AuthenticationWindow
        {...authWindowProps}
        authModalProps={authModalProps}
        handleCancel={handleCloseAuthModal}
        onSuccess={handleOnSuccessAuth}
        operationName={activeAuthAction}
      />
    </PageContainer>
  );
}

const mapStateToProps = state => {
  const {addInvoiceModalProps} = state.transaction;
  const {employees} = state.company;
  const {isAdmin, employee} = state.user;

  return {
    addInvoiceModalProps,
    employees,
    employeeId: employee?.id,
    isAdmin
  }
}


const mapDispatchToProps = {
  batchCreateInvoices: invoicesActions.batchCreateInvoices,
  closeAddInvoiceModal: transactionActions.closeAddInvoiceModal,
  deleteCard: cardActions.deleteCard,
  getBatchExpenses: expenseActions.getBatchExpenses,
  getBankingCardDetails: bankingActions.getUserCardDetails,
  getMainCardDetails: cardActions.getCard,
  getSubscription: subscriptionActions.getSubscription,
  getTags: companyActions.getTags,
  getTransactionsList: transactionActions.getUserCardTransactionsList,
  getUserDetails: bankingActions.getUserDetails,
  pauseCard: cardActions.pauseCard,
  updateCard: cardActions.updateCard,
  updateCardLimits: bankingActions.updateCardLimits,

  linkExpense: invoicesActions.linkExpense,
  unlinkExpense: invoicesActions.unlinkExpense,
  openAddInvoiceModal: transactionActions.openAddInvoiceModal
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPage);
