import { mainConstants } from '../constants';
import {localStorageKeysConstants, KYCModalModeTypes} from '../../constants';

const {COMPANY} = KYCModalModeTypes;

const defaultStateProps = {
  availableModules: {loaded: false, modules: []},
  navigationMenuProps: {dividedKeys: [], items: []}
}

const mainState = {
  ...defaultStateProps,
  currencies: [],
  initialLoading: !!localStorage.getItem(localStorageKeysConstants.TOKEN),
  isOpenMenu: true,
  kycWindowProps: {closeIcon: true, open: false, mode: COMPANY},
  lightKycWindowProps: {open: false},
  loading: false,
  navigationLoading: true,
  permissionMatrix: null,
}

const mainReducer = (state = mainState, action) => {
  const {type, data, loading} = action;
  switch (type) {
    case mainConstants.SET_LOADING:
      return {
        ...state,
        loading
      }
    case mainConstants.SET_INITIAL_LOADING:
      return {
        ...state,
        initialLoading: loading
      }
    case mainConstants.SET_NAVIGATION_LOADING:
      return {
        ...state,
        navigationLoading: loading
      }
    case mainConstants.GET_CURRENCIES:
      return {
        ...state,
        currencies: data
      }
    case mainConstants.GET_PERMISSION_MATRIX:
      return {
        ...state,
        permissionMatrix: data
      }
    case mainConstants.COLLAPSE_NAVIGATION_MENU:
      return {
        ...state,
        isOpenMenu: !state.isOpenMenu
      }
    case mainConstants.OPEN_KYC_WINDOW:
      return {
        ...state,
        kycWindowProps: {
          closeIcon: data.closeIcon || true,
          mode: data.mode || COMPANY,
          open: true
        }
      }
    case mainConstants.CLOSE_KYC_WINDOW:
      return {
        ...state,
        kycWindowProps: {...state.kycWindowProps, open: false}
      }
    case mainConstants.OPEN_LIGHT_KYC_WINDOW:
      return {
        ...state,
        lightKycWindowProps: {...state.lightKycWindowProps, open: true}
      }
    case mainConstants.CLOSE_LIGHT_KYC_WINDOW:
      return {
        ...state,
        lightKycWindowProps: {...state.lightKycWindowProps, open: false}
      }
    case mainConstants.CLEAR_MAIN_DATA:
      return {
        ...state,
        availableModules: defaultStateProps.availableModules,
        navigationLoading: true,
        navigationMenuProps: defaultStateProps.navigationMenuProps
      }
    case mainConstants.SET_AVAILABLE_MODULES:
      return {
        ...state,
        availableModules: {loaded: true, modules: data.modules}
      }
    case mainConstants.SET_NAVIGATION_MENU_PROPS:
      return {
        ...state,
        navigationMenuProps: action.navigationMenuProps
      }
    default:
      return state
  }
};

export default mainReducer;
