import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {
  StyledSpending,
  StyledSpendingHeader,
  StyledSpendingSegmented
} from './StyledSpending';
import OverviewCounters from '../OverviewCounters/OverviewCounters';
import SubscriptionChart from '../SubscriptionChart/SubscriptionChart';
import SpendingBarChart from '../SpendingBarChart';
import {firebaseEvents} from '../../../../snippets/firebase';
import {systemHelpers} from '../../../../utils/helpers';
import routes from '../../../../routes/routes.json';

const Spending = ({
  barChartData,
  chartVariant,
  counterProps,
  handleChangeView,
  lineChartData,
  lineChartDataServices,
  segmentProps,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [selectedView, setSelectedView] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    if (segmentProps.value !== selectedView) setSelectedView(segmentProps.value);
  }, [segmentProps.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onViewChange = (value) => {
    setSelectedView(value);
    if (segmentProps && segmentProps.hasOwnProperty('onChange')) segmentProps.onChange(value);
    systemHelpers.logEvent(firebaseEvents.OVERVIEW_CHANGE_VIEW_TYPE, {type: value});
  }

  const onBarClick = (e, payload) => {
    const subscriptionId = payload?.subscription_id;
    if (subscriptionId) navigate(`${routes.subscriptionsList}/${subscriptionId}`);
  }

  return (
    <StyledSpending
      align='start'
      direction='vertical'
      size='large'
      {...rest}
    >
      <StyledSpendingHeader>
        <OverviewCounters {...counterProps}>
          <StyledSpendingSegmented
            disabled={segmentProps?.disabled || false}
            value={selectedView}
            onChange={onViewChange}
            options={[
              {label: t('all'), value: 'all'},
              {label: `${t('top')} 15`, value: 'top-15'},
            ]}
          />
        </OverviewCounters>
      </StyledSpendingHeader>
      {chartVariant === 'barchart' ? (
        <SpendingBarChart
          barWidth={10}
          data={barChartData}
          onBarClick={onBarClick}
        />
      ) : (
        <SubscriptionChart
          data={lineChartData}
          tooltipServices={lineChartDataServices}
        />
      )}
    </StyledSpending>
  );
}

const counterPropTypes = PropTypes.shape({
  isLoaded: PropTypes.bool,
  value: PropTypes.any
})

Spending.propTypes = {
  barChartData: PropTypes.array,
  chartVariant: PropTypes.oneOf(['barchart', 'linechart']),
  counterProps: PropTypes.shape({
    actualTimeRange: PropTypes.string,
    data: PropTypes.shape({
      actual: counterPropTypes,
      expected: counterPropTypes
    }),
    enableBudgetCounter: PropTypes.bool,
  }),
  handleChangeView: PropTypes.func,
  lineChartData: PropTypes.array,
  lineChartDataServices: PropTypes.array,
  segmentProps: PropTypes.object,
}

Spending.defaultProps = {
  barChartData: [],
  chartVariant: 'linechart',
  lineChartData: [],
  lineChartDataServices: [],
  segmentProps: undefined
}

export default Spending;
