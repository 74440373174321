import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {
  StyledTransactionDetails,
} from './StyledTransactionDetails';
import TransactionDetailsForm from '../TransactionDetailsForm';
import InvoiceDetails from '../InvoiceDetails';
import SpinSmall from '../../../SpinSmall';
import TransactionDetailsHeader from '../TransactionDetailsHeader';
import {expenseActions} from '../../../../state/actions';
import {transactionTypesConstants} from '../../../../constants';
import {fileHelpers, objectHelpers, systemHelpers} from '../../../../utils/helpers';
import {firebaseEvents} from '../../../../snippets/firebase';

const defaultAttachment = {
  data: undefined,
  file: undefined,
  name: undefined
}

const TransactionDetails = ({
  dispatch,
  availableLinks,
  edit,
  subscription,
  transaction,
  getExpenseAttachment,
  getTransactionExpense,
  linkExpense,
  updateExpense,

  onExpenseUpdate,
  onInvoiceEdit,
  onInvoiceRemove,
  ...rest
}) => {
  const [attachment, setAttachment] = useState(defaultAttachment);
  const [isLoadingAttachment, setIsLoadingAttachment] = useState(false);

  const finishLoadingAttachment = () => setIsLoadingAttachment(false);

  useEffect(() => {
    if (transaction) {
      const {expense_id: expenseId} = transaction;
      if (expenseId && transaction.expense?.is_attachment_uploaded) {
        setIsLoadingAttachment(true);
        getExpenseAttachment(
          expenseId,
          (data) => {
            const contentType = data['content-type']
            const file = `data:${contentType};base64,${data.file}`;
            setAttachment({
              data,
              contentType,
              file,
              name: data.filename
            });
            finishLoadingAttachment();
          },
          () => {
            setAttachment(defaultAttachment);
            finishLoadingAttachment();
          }
        );
      } else if (attachment.file) {
        setAttachment(defaultAttachment)
      }
    }
  }, [transaction]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitForm = (data, successCallback) => {
    const {description, tags} = data;
    if (transaction.expense) {
      const {expense} = transaction;
      const expenseId = expense.id;
      if (description !== transaction.description) {
        updateExpense(expenseId, {description}, 'description');
      }
      if (!objectHelpers.arraysIsEqual(tags, expense.tags_list)) {
        updateExpense(expenseId, {tags}, 'tags');
      }
      onExpenseUpdate(
        {
          ...expense,
          description,
          tags_list: tags
        }
      );
      systemHelpers.logEvent(firebaseEvents.TRANSACTIONS_EDIT_TRANSACTION_DETAILS);
    }
    successCallback && successCallback();
  }

  const handleRemoveInvoice = () => {
    if (onInvoiceRemove) {
      setIsLoadingAttachment(true);
      onInvoiceRemove(
        () => {
          setAttachment(defaultAttachment);
          finishLoadingAttachment();
        },
        finishLoadingAttachment
      );
    }
  }

  const handleOnInvoiceDownload = () => attachment.data && fileHelpers.saveFile(attachment.data);

  const isPayInType = objectHelpers.getObjProp(transaction, 'transaction_type') === transactionTypesConstants.PAY_IN;

  const checkLinkAvailability = (key) => availableLinks.includes(key);

  return (
    <StyledTransactionDetails {...rest}>
      <TransactionDetailsHeader
        availableSubscriptionLink={checkLinkAvailability('subscription')}
        subscription={subscription}
        transaction={transaction}
      />
      {!isPayInType && (
        <SpinSmall spinning={isLoadingAttachment}>
          <InvoiceDetails
            attachment={attachment}
            isDisableEdit={Boolean(attachment.file) || !edit}
            isDisableClose={!edit}
            isEnableDownloadButton={true}
            onClose={handleRemoveInvoice}
            onDownload={handleOnInvoiceDownload}
            onEdit={onInvoiceEdit}
            transaction={transaction}
          />
        </SpinSmall>
      )}
      <TransactionDetailsForm
        availableCardLink={checkLinkAvailability('card')}
        edit={edit}
        onSubmit={handleSubmitForm}
        transaction={transaction}
      />
    </StyledTransactionDetails>
  );
}

TransactionDetails.propTypes = {
  availableLinks: PropTypes.arrayOf(PropTypes.string),
  edit: PropTypes.bool,
  onExpenseUpdate: PropTypes.func,
  onInvoiceEdit: PropTypes.func,
  onInvoiceRemove: PropTypes.func,
  subscription: PropTypes.object,
  transaction: PropTypes.object
}

TransactionDetails.defaultProps = {
  availableLinks: ['card', 'subscription'],
  edit: true,
}

const mapDispatchToProps = {
  updateExpense: expenseActions.updateExpense,
  getTransactionExpense: expenseActions.getTransactionExpense,
  getExpenseAttachment: expenseActions.getExpenseAttachment,
}

export default connect(null, mapDispatchToProps)(TransactionDetails);
