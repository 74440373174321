import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useIsEmployeeLoaded} from '../../hooks';

const PageDocumentDetails = ({
  children,
  title,
  useDefaultTitle,

  initialLoading,
  isAuthenticated
}) => {

  const [t] = useTranslation('main');
  const isEmployeeLoaded = useIsEmployeeLoaded();

  const getTitle = () => {
    const defaultTitle = t('pageTitles.defaultTitle');
    let pageTitle = `${title}`;
    if (useDefaultTitle) pageTitle = `${pageTitle} | ${defaultTitle}`
    if (initialLoading) {
      return defaultTitle;
    } else {
      return isAuthenticated ? isEmployeeLoaded ? pageTitle : defaultTitle : pageTitle;
    }
  }

  const pageTitle = getTitle();

  return (
    <Helmet>
      <title>{pageTitle}</title>
      {children}
    </Helmet>
  );
};

PageDocumentDetails.propTypes = {
  useDefaultTitle: PropTypes.bool,
  title: PropTypes.string.isRequired
}

PageDocumentDetails.defaultProps = {
  useDefaultTitle: true
}

const mapStateToProps = state => {
  const {main, user} = state;
  const {isAdmin, isAuth} = user;
  const {initialLoading} = main;
  const {companyState} = state.banking;
  return {
    isAdmin,
    initialLoading,
    isAuthenticated: isAuth,
    companyState
  }
}

export default connect(mapStateToProps, null)(PageDocumentDetails);
