import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Avatar, Space} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {StyledProfileDropdown, StyledProfileDropdownHeader, StyledProfileDropdownMenu} from './StyledProfileDropdown';
import {authActions} from '../../state/actions';
import {AvatarFilledIcon, LogoutIcon} from '../../icons';
import routes from '../../routes/routes.json';
import {useIsEmployeeChanged} from '../../hooks';
import {elementHelpers, objectHelpers, textHelpers} from '../../utils/helpers';

const ProfileDropdown = ({
  dispatch,
  logout,
  user,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState([]);

  const isEmployeeChanged = useIsEmployeeChanged();

  useEffect(() => isEmployeeChanged && setSelectedKeys([]), [isEmployeeChanged]);

  useEffect(() => {
    elementHelpers.updateMenuItems({location, items: navItems, selectedKeys, setSelectedKeys});
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMenuClick = (e) => {
    const {key} = e;
    const item = navItems.find(i => i.key === key);
    if (item) {
      let selectedKeys = [];
      if (item.path) {
        navigate(item.path);
        selectedKeys = [key];
      } else if (item.onClick) {
        item.onClick();
      }
      setSelectedKeys(selectedKeys);
    }
  }

  const handleLogout = () => {
    logout();
    navigate('/');
  }

  const navItems = [
    {key: 'settings', label: 'settings', path: routes.profile, icon: <AvatarFilledIcon />},
    {key: 'logout', label: 'logout', icon: <LogoutIcon />, onClick: handleLogout},
  ]
    .map(i => ({...i, label: t(`profileDropdown.navigation.${i.label}`)}));


  const email = objectHelpers.getObjProp(user, 'email');
  const logo = objectHelpers.getObjProp(user, 'logo');
  const name = objectHelpers.getObjProp(user, 'full_name');

  return (
    <StyledProfileDropdown {...rest}>
      <StyledProfileDropdownHeader
        size='middle'
      >
        <Avatar
          size={48}
          src={logo}
        >
          {textHelpers.getInitials(name)}
        </Avatar>
        <Space
          direction='vertical'
          size='small'
        >
          <h3>{name}</h3>
          <p>{email}</p>
        </Space>
      </StyledProfileDropdownHeader>
      <StyledProfileDropdownMenu
        items={navItems}
        onClick={handleMenuClick}
        selectedKeys={selectedKeys}
      />
    </StyledProfileDropdown>
  );
}

const mapStateToProps = state => {
  const {user} = state.user;

  return {
    user
  }
}

const mapDispatchToProps = {
  logout: authActions.logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDropdown);
