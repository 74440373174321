import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {userRolesConstants} from '../constants';
import {backendEndpoints} from '../api';

export const useHasAccess = (action) => {
  const [access, setAccess] = useState(false);
  const {permissionMatrix, userRole} = useSelector(state => ({
    permissionMatrix: state.main.permissionMatrix,
    userRole: state.user?.employee?.role
  }));

  useEffect(() => {
    let roleKey = Object.keys(userRolesConstants).find(key => userRolesConstants[key] === userRole);
    let endpoint = backendEndpoints[action] || undefined;
    let access = false;
    let allowedRoles = [];
    if (endpoint && permissionMatrix) {
      let permissionMatrixItem, endpointPermission;
      let method = action.split('_')[0].toLowerCase();
      let replacedPathKeyValue = '<key>';
      endpoint = endpoint.replaceAll(/<[^>]*>/g, replacedPathKeyValue).replace(/^\/v[12]/, '');

      // search permission
      Object.keys(permissionMatrix).forEach(permissionMatrixKey => {
        if (endpointPermission) return;
        permissionMatrixItem = permissionMatrix[permissionMatrixKey];
        Object.keys(permissionMatrixItem).forEach(permissionMatrixItemKey => {
          if (endpointPermission) return;
          if (endpoint === permissionMatrixItemKey.replaceAll(/{[^>]*}/g, replacedPathKeyValue)) {
            endpointPermission = permissionMatrixItem[permissionMatrixItemKey];
          }
        });
      })
      // check if permission has needed method and user role
      if (endpointPermission && endpointPermission.hasOwnProperty(method)) {
        let methodObj = endpointPermission[method];
        allowedRoles = Array.isArray(methodObj) ? methodObj : methodObj?.allowed_roles || [];
        access = allowedRoles.includes(roleKey);
      }
      setAccess(access);
    }
  }, [action, permissionMatrix, userRole]); // eslint-disable-line react-hooks/exhaustive-deps

  return access;
}

