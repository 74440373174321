import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Space} from 'antd';
import {connect} from 'react-redux';
import CopyField from '../../../../../CopyField';
import SpinSmall from '../../../../../SpinSmall';
import {
  StyledCardDetailsModal,
  StyledCardDetailsTimer,
  StyledCardDetailsCard,
  StyledCardDetailsModalImageContainer
} from './StyledCardDetailsModal';
import {cardsHelpers, dateHelpers} from '../../../../../../utils/helpers';
import {alertActions} from '../../../../../../state/actions';
import {useIsMount} from '../../../../../../hooks';

let interval = null;

const CardDetailsModal = ({
  dispatch,

  companyDetails,
  image,
  initialSeconds,
  handleCancel,
  handleOk,
  open,
  startTimer,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'cards']);
  const [cardDetails, setCardDetails] = useState({
    cvv: null,
    expired: null,
    isLoaded: false,
    number: null,
    owner: null
  });
  const [seconds, setSeconds] = useState(initialSeconds);
  const isMounted = !useIsMount();

  const [cardImage, setCardImage] = useState(image);

  useEffect(() => {
    if (image && open && image !== cardImage) {
      if (cardDetails.isLoaded) setCardDetails({...cardDetails, isLoaded: false});

      cardsHelpers
        .getCardDetailsFromImage(image)
        .then(details => setCardDetails({...details, isLoaded: true}))
        .catch(() => setCardDetails({...cardDetails, isLoaded: true}));
      setCardImage(image);
    } else if (cardImage && !open) {
      setCardImage(undefined);
    }
  }, [image]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMounted && !open) {
      // clear the form fields and errors after closing the modal
      setTimeout(() => {
        setSeconds(initialSeconds);
      }, [300]);
    }
    if (!open && cardImage) {
      setCardImage(undefined);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const startTimerHandle = () => {
    interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);
  };

  useEffect(() => {
    if (seconds > 0 && open && startTimer) {
      startTimerHandle();
      return () => {
        if (interval) clearInterval(interval);
      }
    } else if (seconds <= 0) {
      handleCancel && handleCancel();
    }
  }, [seconds, startTimer, open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCopy = () => alertActions.success(`${t('dataCopiedToClipboard')}!`);

  const footer = [
    <StyledCardDetailsTimer key='timer'>
      {t('time')} {t('left')}:
      <span className='time'>{dateHelpers.getSecondsTime(seconds)}</span>
    </StyledCardDetailsTimer>,
    <Button
      key='submit'
      onClick={() => handleCancel && handleCancel()}
      size='large'
      type='primary'
    >
      {t('done')}
    </Button>
  ];

  const copyFieldProps = {
    emptyMessage: t('cards:whoopsCouldntReadTheCardTryAgainLater'),
    enabledEmpty: cardDetails.isLoaded,
    onCopy: handleCopy
  }

  return (
    <StyledCardDetailsModal
      footer={footer}
      open={open}
      onCancel={handleCancel}
      title={`${t('card')} ${t('details')}`}
      width={806}
      {...rest}
    >
      <Space direction='vertical' size='middle'>
        <SpinSmall spinning={!cardDetails.isLoaded}>
          <Space size='middle' className='card-details'>
            <StyledCardDetailsModalImageContainer>
              {cardImage && (
                <img src={cardImage} alt='card' className='card-image'/>
              )}
            </StyledCardDetailsModalImageContainer>

            <StyledCardDetailsCard>
              <CopyField
                {...copyFieldProps}
                label='Name on Card:'
                value={cardDetails.owner}
              />
              <CopyField
                {...copyFieldProps}
                label='Card number:'
                value={cardDetails.number}
              />
              <CopyField
                {...copyFieldProps}
                label='Card expiry date:'
                value={cardDetails.expired}
              />
              <CopyField
                {...copyFieldProps}
                label='CVV:'
                value={cardDetails.cvv}
              />
            </StyledCardDetailsCard>
          </Space>
        </SpinSmall>

        <StyledCardDetailsCard title='Billing Information'>
          <CopyField
            label='Company name:'
            onCopy={handleCopy}
            value={companyDetails.name}
          />
          <CopyField
            label='Address:'
            onCopy={handleCopy}
            value={companyDetails.address}
          />
          <CopyField
            label='VAT:'
            onCopy={handleCopy}
            value={companyDetails.vat}
          />
        </StyledCardDetailsCard>
      </Space>

    </StyledCardDetailsModal>
  );
}

CardDetailsModal.propTypes = {
  company: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    name: PropTypes.string,
    vat: PropTypes.string
  }),
  image: PropTypes.string,
  initialSeconds: PropTypes.number,
  handleCancel: PropTypes.func,
  startTimer: PropTypes.bool
}

CardDetailsModal.defaultProps = {
  initialSeconds: 59,
  startTimer: true
}

const mapStateToProps = state => {
  return {
    companyDetails: cardsHelpers.getBankingCompanyDetails(state)
  }
}

export default connect(mapStateToProps, null)(CardDetailsModal);
