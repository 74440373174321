import { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import {cardVerificationLightStatusesConstants as lightStatus} from '../constants';

export const useIsRequiredCardVerification = () => {
  const {isSupport, verification} = useSelector(state => ({
    isSupport: state.user.isSupport,
    verification: state.banking.verification
  }));
  const [checked, setChecked] = useState(false);
  const [required, setRequired] = useState(false);

  useEffect(() => {
    let isRequired;
    const isChecked = verification?.isChecked || false;
    if (isSupport || !isChecked) {
      isRequired = false;
    } else {
      isRequired = verification.isRequired && ![lightStatus.REFUSED, lightStatus.PENDING_FILE_UPLOADED].includes(verification.status);
    }
    if (isRequired !== required) setRequired(isRequired);
    if (isChecked !== checked) setChecked(isChecked);
  }, [isSupport, verification]); // eslint-disable-line react-hooks/exhaustive-deps

  return { checked, required };
}

