import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Avatar, Button, Space} from 'antd';
import {
  StyledHistoryDetails,
  StyledHistoryDetailsButtonContainer,
  StyledHistoryDetailsMessage,
  StyledHistoryDetailsMessageFieldValue
} from './StyledHistoryDetails';
import History from '../../../../../History';
import {subscriptionActions} from '../../../../../../state/actions';
import {
  amountHelpers,
  dateHelpers,
  objectHelpers,
  subscriptionsHelpers,
  textHelpers
} from '../../../../../../utils/helpers';

const {getObjProp: gObjProp} = objectHelpers;

const perPagePagination = 10;

const defaultQuery = {
  order_by: 'created_at',
  per_page: perPagePagination,
}

const excludedHistoryFields = [];

const HistoryDetails = ({
  dispatch,
  getSubscriptionLogs,
  subscription,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [pagination, setPagination] = useState({next_page: null, cursor: null});
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const trans = (key, options) => t(`subscriptions:${key}`, options);

  const getFieldValue = (details, key) => {
    let value = details[key];
    const fields = {
      'budget_limit': () => amountHelpers.getAmountWithCurrencyCode(value),
      'billed': () => subscriptionsHelpers.getBillType(value),
      'category': () => {
        const category = subscriptionsHelpers.getSubscriptionCategory(value);
        return category && category.key ? t(category.key) : value;
      },
      'card_limit_period': () => subscriptionsHelpers.getCardLimitType(value),
      'card_owner': () => objectHelpers.getObjProp(value, 'full_name'),
      'custom_limit': () => amountHelpers.getAmountWithCurrencyCode(value),
      'duration': () => subscriptionsHelpers.getDurationType(value),
      'expected_first_payment': () => subscriptionsHelpers.getSubscriptionDate(details, key),
      'owner': () => objectHelpers.getObjProp(value, 'full_name'),
      'payment_type': () => subscriptionsHelpers.getPaymentType(value),
      'target_status': () => subscriptionsHelpers.getStatusType(value),
      'status': () => subscriptionsHelpers.getStatusType(value)
    }
    if (fields.hasOwnProperty(key)) {
      value = fields[key]();
      if (typeof value === 'string') value = t(value);
    }
    if (Array.isArray(value)) value = value.length === 0 ? '' : typeof value === 'boolean' ? value : value.join(', ');

    return value;
  }

  const getHistoryItems = (data) => {
    return data.map(d => {
      const {details} = d;
      const emptyFields = d.empty_fields || [];
      let changedFields = d.changed_fields || [];
      let fieldsData = [];
      const reporter = gObjProp(d, 'reported_by');
      const reporterLogo = gObjProp(reporter, 'logo');
      const reporterName = gObjProp(reporter, 'full_name');
      // display only allowed fields
      changedFields = [...changedFields, ...emptyFields].filter(field => !excludedHistoryFields.includes(field));
      // get field values
      changedFields.forEach(field => fieldsData.push({name: field, value: getFieldValue(details, field)}));
      // left fields only with value
      fieldsData = fieldsData.filter(field => field.value);

      return fieldsData.length ? {
        key: d.id,
        date: dateHelpers.getTimestampDate(d.created_at, 'DD/MM/YY'),
        message: (
          <StyledHistoryDetailsMessage size='middle' align='start'>
            <Avatar src={reporterLogo || undefined}>
              {textHelpers.getInitials(reporterName)}
            </Avatar>
            <div className='message-details'>
              <div><span className='fw-500'>{reporterName}</span> {t('changedTheseValues')}:</div>
              <Space direction='vertical' size={2}>
                {fieldsData.map(({name, value}, index) => {
                  if (value !== undefined) {
                    let displayedValue = value !== '' && value !== null ? value : t('setToEmpty');
                    return (
                      <span key={`field-${name}-${index}`}>
                        <span>
                          {trans(`subscriptionFields.${name}`)}: <StyledHistoryDetailsMessageFieldValue>{displayedValue}</StyledHistoryDetailsMessageFieldValue>
                        </span>
                      </span>
                    )
                  } else {
                    return ''
                  }
                })}
              </Space>
            </div>
          </StyledHistoryDetailsMessage>
        )
      } : null
    }).filter(value => value); // exclude message with no changed fields
  }

  useEffect(() => {
    const subscriptionId = gObjProp(subscription, 'id');
    if (subscriptionId) {
      getSubscriptionLogs(
        subscriptionId,
        defaultQuery,
        ({logs, pagination}) => {
          setHistoryData(getHistoryItems(logs));
          setPagination(pagination);
        }
      )
    }
  }, [subscription]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowMore = () => {
    const subscriptionId = gObjProp(subscription, 'id');
    if (subscriptionId) {
      setLoading(true);
      getSubscriptionLogs(
        subscriptionId,
        {
          ...defaultQuery,
          cursor: pagination.cursor
        },
        ({logs, pagination}) => {
          setHistoryData([
            ...historyData,
            ...getHistoryItems(logs)
          ]);
          setPagination(pagination);
          setLoading(false);
        },
        () => setLoading(false)
      )
    }
  }

  const isEnableLoadMore = pagination.next_page;

  return (
    <StyledHistoryDetails
      direction='vertical'
      align='center'
      size='large'
      {...rest}
    >
      <History data={historyData} />
      <StyledHistoryDetailsButtonContainer>
        {isEnableLoadMore && (
          <Button
            loading={loading}
            onClick={() => !loading && handleShowMore()}
            size='large'
            type='default'
          >
            {t('showMore')}
          </Button>
        )}
      </StyledHistoryDetailsButtonContainer>
    </StyledHistoryDetails>
  );
}

HistoryDetails.propTypes = {
  subscription: PropTypes.shape({
    id: PropTypes.string,
  }),
}

const mapDispatchToProps = {
  getSubscriptionLogs: subscriptionActions.getSubscriptionLogs
}

export default connect(null, mapDispatchToProps)(HistoryDetails);


