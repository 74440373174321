import React, {useState} from 'react';
import {Avatar, Badge, Button, Dropdown} from 'antd';
import {BellFilled, QuestionCircleFilled} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import './Toolbar.css';
import {StyledToolbar, StyledToolbarItem} from './StyledToolbar';
import {useOnClickOutside} from '../../hooks';
import routes from '../../routes/routes.json';
import {colorsStyles} from '../../utils/styles';
import {objectHelpers, textHelpers} from '../../utils/helpers';

const linkTypes = ['support'];

const Toolbar = ({
  notificationBadgeColor,
  notificationCount,
  notificationDropdownRender,
  profileDropdownRender,
  user,
  ...rest
}) => {

  const getUserProp = (propName) => objectHelpers.getObjProp(user, propName);

  const location = useLocation();

  const [isOpenDropdown, setIsOpenDropdown] = useState({
    notification: false,
    profile: false,
  });

  const notificationElement = (
    <Badge
      color={notificationBadgeColor}
      count={notificationCount}
      overflowCount={99}
    >
      <Button
        icon={<BellFilled />}
        shape='circle'
        type='default'
      />
    </Badge>
  );

  const profileElement = (
    <Avatar src={getUserProp('logo')} size={24}>
      {textHelpers.getInitials(getUserProp('name'))}
    </Avatar>
  );

  const onClickOutside = (e, dropdownType) => {
    const parentSelector = '.ant-dropdown';
    if (!e.target.closest(parentSelector)) setIsOpenDropdown({...isOpenDropdown, [dropdownType]: false});
  }

  const getDropdownElement = ({element, dropdownRender, type, ...rest}) => {
    return dropdownRender ? (
      <Dropdown
        overlayClassName='navigation-toolbar'
        dropdownRender={dropdownRender}
        onClick={() => setIsOpenDropdown({...isOpenDropdown, [type]: !isOpenDropdown[type]})}
        open={isOpenDropdown[type]}
        trigger={['click']}
        {...rest}
      >
        {element}
      </Dropdown>
    ) : element;
  }

  const getItemClassName = (type, className) => {
    let cName = '';
    if (linkTypes.includes(type)) {
      if (location && location.pathname === `/${type}`) cName = 'open';
    } else {
      cName = isOpenDropdown[type] ? 'open' : '';
    }
    if (className) cName = `${cName} ${className}`;
    return cName;
  };

  return (
    <StyledToolbar {...rest}>
      <StyledToolbarItem className={getItemClassName('support')}>
        <Link to={routes.support}>
          <Button
            icon={<QuestionCircleFilled />}
            shape='circle'
          />
        </Link>
      </StyledToolbarItem>

      <StyledToolbarItem
        className={getItemClassName('notification', notificationCount > 0 ? 'notified' : undefined)}
        ref={useOnClickOutside((e) => onClickOutside(e, 'notification'), isOpenDropdown.notification)}
      >
        {getDropdownElement({element: notificationElement, dropdownRender: notificationDropdownRender, type: 'notification'})}
      </StyledToolbarItem>

      <StyledToolbarItem
        className={getItemClassName('profile')}
        ref={useOnClickOutside((e) => onClickOutside(e, 'profile'), isOpenDropdown.profile)}
      >
        {
          getDropdownElement({
            arrow: true,
            element: profileElement,
            dropdownRender: profileDropdownRender,
            type: 'profile'
          })
        }
      </StyledToolbarItem>
    </StyledToolbar>
  );
}

Toolbar.propTypes = {
  notificationDropdownRender: PropTypes.func,
  notificationBadgeColor: PropTypes.string,
  notificationCount: PropTypes.number,
  profileDropdownRender: PropTypes.func,
  user: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string
  })
}

Toolbar.defaultProps = {
  notificationBadgeColor: colorsStyles.primary_6,
  notificationCount: 0
}

export default Toolbar;
