import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Select, Space} from 'antd';
import {parsePhoneNumber} from 'libphonenumber-js';
import {StyledPhoneNumberInput, StyledPhoneInputSelect, StyledPhoneInputSelectOptionContent} from './StyledPhoneNumberInput';
import {localizationHelpers, objectHelpers, textHelpers} from '../../utils/helpers';

const selectOptions = localizationHelpers.getCountryOptions('country_code').map(o => ({...o, value: `+${o.value}`}));

const PhoneNumberInput = ({
  disabled,
  onChange,
  placeholder,
  phoneCode,
  size,
  value,
  ...rest
}) => {
  const [code, setCode] = useState(phoneCode || '');
  const [number, setNumber] = useState('');

  useEffect(() => {
    if (value) {
      try {
        const number = parsePhoneNumber(value);
        setNumber(number.nationalNumber);
        setPlusCode(number.countryCallingCode)
      } catch (e) {
      }
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!objectHelpers.isValidValue(value) && phoneCode) {
      setPlusCode(phoneCode);
    }
  }, [phoneCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const setPlusCode = (code) => setCode(`+${code}`);

  const onNumberChange = (e) => {
    let value = textHelpers.getTextDigits(e.target.value);
    setNumber(value);
    handleOnChange(`${code}${value}`);
  }

  const handleOnChange = (value) => onChange && onChange(value);

  const onCodeChange = (code) => {
    setCode(code);
    handleOnChange(`${code}${number}`);
  }

  const selectBefore = (
    <StyledPhoneInputSelect
      disabled={disabled}
      dropdownStyle={{minWidth: 250}}
      filterOption={(input, option) =>
        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={onCodeChange}
      optionLabelProp='value'
      size={size}
      showSearch={true}
      value={code}
    >
      {selectOptions.map(({image, label, value}, key) => (
        <Select.Option
          key={key}
          label={label}
          value={value}
        >
          <StyledPhoneInputSelectOptionContent>
            <Space>
              {image}
              {label}
            </Space>
            {value}
          </StyledPhoneInputSelectOptionContent>
        </Select.Option>
      ))}
    </StyledPhoneInputSelect>
  )

  return (
    <StyledPhoneNumberInput
      {...rest}
      addonBefore={selectBefore}
      disabled={disabled}
      onChange={onNumberChange}
      placeholder={placeholder}
      size={size}
      value={number}
    />
  );
}

PhoneNumberInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  phoneCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

PhoneNumberInput.defaultProps = {
  disabled: false
}

export default PhoneNumberInput;
