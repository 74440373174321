import styled from 'styled-components';
import {Segmented, Space} from 'antd';
import Card from '../../components/Card';
import PageContainer from '../../components/PageContainer';
import Spin from '../../components/Spin';
import {bordersStyles, typographyStyles} from '../../utils/styles';

const PADDING = '24px';
const PANEL_HEIGHT = `calc(100vh - 66px - ${PADDING} * 2)`;
const RIGHT_SIDE_PANEL_WIDTH = '212px';

const StyledOverviewPageContainer = styled(PageContainer)`
  width: 100%;
  
  .overview-main-spin {
    display: flex;
    width: 100%;
    
    & > .ant-spin-container {
      display: flex;
      width: 100%;
      & > .ant-space {
        width: 100%;
        
        & > .ant-space-item {
          display: flex;
          width: 100%;
        }
      }
    }
  }
  
  .spending-selectors {

    .ant-dropdown-trigger {
      font-size: 14px;
    }
    
    .ant-select {
      min-width: 152px;
      .ant-select-selector {
        border: 1px solid ${props => props.theme.colors.neutral_5};
      }
      
      
      &:hover,
      &.ant-select-focused,
      &.ant-select-open {
        &:not(.ant-select-disabled):not(.ant-pagination-size-changer) {
          .ant-select-selector {
            background-color: ${props => props.theme.colors.neutral_1};
            border-color: ${props => props.theme.colors.primary_3};
          }
        }
      }
    }
  }
`;

const StyledOverviewPageContent = styled(Space)`
  padding: 24px;
  width: 100%;
  
  & > .ant-space-item {
    display: flex;
    height: 100%;
    min-height: 100%;
    
    & > .ant-space {
      width: 100%;
      position: relative;
    }
    
    &:nth-of-type(1) {
      min-width: calc(100% - ${PADDING} - ${RIGHT_SIDE_PANEL_WIDTH});
      width: calc(100% - ${PADDING} - ${RIGHT_SIDE_PANEL_WIDTH});
    }
    
    &:nth-of-type(2) {
      min-width: ${RIGHT_SIDE_PANEL_WIDTH};
      max-height: ${PANEL_HEIGHT};
      position: sticky;
      top: calc(66px + ${PADDING});
      width: ${RIGHT_SIDE_PANEL_WIDTH};
    }
  }
`;

const StyledOverviewPageSpin = styled(Spin)`
  display: flex;
  min-height: ${PANEL_HEIGHT};
  
  & > .ant-spin-container {
    display: flex;
  }
`;


const StyledOverviewPageCard = styled(Card)`
  background-color: unset;
  
  &:not(.ant-card-bordered) {
    box-shadow: none;
  }
  
  .ant-card-body {
    padding: 0;
  }
  
  .ant-card-head {
    border-bottom: none;
    font-weight: 500;
    padding: 0;
    justify-content: start;
  }
  
  &.cost-center-chart-card {
    .ant-empty-description {
      p {
        max-width: 220px;
      }
    }
    
    &.no-padding {
      & > .ant-card-body {
        padding: 0;
      }
    }
  }
  
  .cost-center-chart {
    height: 243px;
    padding: 8px 24px;
    overflow: auto;
  }
`;

const StyledOverviewPageCardRightSideSpace = styled(Space)`
  & > .ant-space-item {
    display: flex;
  }
`;

const StyledOverviewPageSpendingPlaceholder = styled.div`
  ${bordersStyles.default};
  background-color: ${props => props.theme.colors.neutral_1};
  height: ${props => props.fullheight ? PANEL_HEIGHT : '442px'};
  
  .ant-space {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    
    h3, p {
      margin: 0;
    }
    
    h3 {
      ${typographyStyles.heading.h5};

      color: ${props => props.theme.colors.neutral_10};
    }
    
    p {
      ${typographyStyles.body.regular};
      
      color: ${props => props.theme.colors.neutral_8};
    }
  }
  
  svg {
    height: 100%;
    width: 100%;
  }
`;

const StyledOverviewPageSegmented = styled(Segmented)`
  ${bordersStyles.default};
  
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 5px;
  
  .ant-segmented-item {
    color: ${props => props.theme.colors.neutral_10};
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    
    &.ant-segmented-item-selected {
      background-color: ${props => props.theme.colors.primary_7};
      color: ${props => props.theme.colors.neutral_1};
    }
  }
  
  .ant-segmented-item-label {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 28px;
    min-width: 28px;
    padding: 0 8px;
  }
`;

export {
  StyledOverviewPageContainer,
  StyledOverviewPageContent,
  StyledOverviewPageSpin,
  StyledOverviewPageCard,
  StyledOverviewPageCardRightSideSpace,
  StyledOverviewPageSpendingPlaceholder,
  StyledOverviewPageSegmented
}