import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {useWindowDimensions} from '../../hooks';
import {authActions} from '../../state/actions';
import {StyledCompanyInvitePage, StyledCompanyInvitePageContent} from './StyledCompanyInvitePage';
import {objectHelpers, systemHelpers} from '../../utils/helpers';

const mobileScreenSize = 767;

const CompanyInvitePage = ({
  invitationDetails,
  isAuth,
  currentUser,
  checkedAuth,
  getInvitationDetails,
  setCompanyInviteToken
}) => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [trans] = useTranslation('settings');
  const [isValid, setIsValid] = useState(false);
  const [isAvailableContent, setIsAvailableContent] = useState(false);
  const { width } = useWindowDimensions();

  const t = (key, options) => trans(`pages.invite.${key}`, options);

  useEffect(() => {
    if (token && !isAuth) getInvitationDetails(token);
  }, [getInvitationDetails, token, isAuth]);

  useEffect(() => {
    const isMobile = width < mobileScreenSize;
    if (checkedAuth && token) {
      if (!isMobile) {
        onAction();
      } else {
        setIsAvailableContent(true);
      }
    }
  }, [checkedAuth, token, width]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (invitationDetails) {
      const email = objectHelpers.getObjProp(currentUser, 'email');
      const availableTTL = systemHelpers.checkAvailableTokenTTL(invitationDetails, false);
      setIsValid(
        isAuth
          ? email === invitationDetails.invited_by_email && availableTTL
          : availableTTL
      );
    }
  }, [invitationDetails, currentUser, isAuth]);

  const onAction = () => {
    setCompanyInviteToken(token);
    navigate('/');
  };

  const getProp = (name) => objectHelpers.getObjProp(invitationDetails, name);

  const companyName = getProp('company_name');

  const appLink = systemHelpers.getMobileAppPath(`company/invite/${token}`);

  return (
    <StyledCompanyInvitePage>
      {!isAuth && isAvailableContent && checkedAuth && (
        <StyledCompanyInvitePageContent>
          {invitationDetails && isValid ? (
            <>
              <h3>{t('title', {name: companyName})}</h3>
              <p>{t('description', {companyName, name: getProp('sender_name')})}</p>
              {!isAuth && width < mobileScreenSize && <a href={appLink}>{t('openApp')}</a>}
            </>
          ) : (
            <p>{t('linkExpired')}</p>
          )}
        </StyledCompanyInvitePageContent>
      )}
    </StyledCompanyInvitePage>
  );
}

const mapStateToProps = state => {
  const {invitationDetails} = state.auth;
  const {user, checkedAuth} = state.user;
  return {
    currentUser: user,
    invitationDetails,
    checkedAuth
  }
}

const mapDispatchToProps = {
  getInvitationDetails: authActions.getInvitationDetails,
  setCompanyInviteToken: authActions.setCompanyInviteToken
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInvitePage);
