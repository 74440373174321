import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import SubscriptionTooltip from '../SubscriptionTooltip';
import {
  StyledSubscriptionChart
} from './StyledSubscriptionChart';
import theme from '../../../../theme';
import {amountHelpers, dateHelpers, objectHelpers} from '../../../../utils/helpers';


const {colors} = theme;

const tickColor = colors.neutral_7;
const actualColor = colors.primary_9;
const expectedColor = colors.neutral_6;

const currentDate = dateHelpers.getDateWithMonth(new Date(), 'YYYY-MM-DD');

const checkIsActiveDot = (props) => {
  const date = objectHelpers.getObjProp(props.payload, 'date');
  return props?.payload?.isActive || date === currentDate;
}

export const CustomizedAxisTick = (props) => {
  const {x, y, payload, getPriceValue, className, textAnchor} = props;
  const {value} = payload;
  const text = getPriceValue ? getPriceValue(value) : `€${payload.value}`;
  return (
    <text
      className={className}
      dy={4}
      fontSize={12}
      fill={tickColor}
      textAnchor={textAnchor}
      x={x}
      y={y}
    >
      {text}
    </text>
  )
}

const CustomizedDot = (props) => {
  const {cx, cy, payload, propName, fill} = props;
  if (!objectHelpers.isNaV(payload[propName])) {
    if (props.isActive) {
      return (
        <>
          <circle
            opacity='0.3'
            cx={cx}
            cy={cy}
            r='14.5'
            fill={fill}
          />
          <circle
            cx={cx}
            cy={cy}
            r='5'
            fill='white'
            stroke={fill}
            strokeWidth='3'
          />
        </>
      )
    }
    return (
      <circle
        cx={cx}
        cy={cy}
        r='3'
        fill='#F0F4FF'
        stroke={fill}
        strokeWidth='2'
      />
    );
  } else {
    return false;
  }
};

const axisProps = {
  stroke: tickColor,
  tickLine: false
};

const lineProps = {
  activeDot: {r: 5},
  fillOpacity: 0,
  strokeWidth: 2,
  type: 'linear'
};

const SubscriptionChart = ({
  data,
  tooltipServices
}) => {
  const [tooltipProps, setTooltipProps] = useState({
    actualSpend: 0,
    expectedSpend: 0,
    emptyText: {
      actual: '-',
      expected: '-'
    },
    loading: false,
    services: []
  });
  const [activeLabel, setActiveLabel] = useState(null);

  const getAmountWithCurrencyCode = (value) => amountHelpers.getAmountWithCurrencyCode(value, undefined, undefined, true, false);

  const lineChartMargin = useMemo(() => {
    let left = 0;
    const maxSpendingValue = Math.max(...data.map(d => Math.max(d.expected || 0, d.current || 0))) || 0;
    const maxSpendingValueLength = String(parseInt(maxSpendingValue)).length;
    if (maxSpendingValueLength > 4) {
      left = {
        5: 5,
        6: 10,
        7: 15,
      }[maxSpendingValueLength] || 20;
    }
    return {
      bottom: 0,
      left,
      top: 0,
      right: 5,
    }
  }, [data]);

  const handleOnMouseMove = (props) => {
    const {activeLabel: actLabel, isTooltipActive} = props;
    if (activeLabel === actLabel || !isTooltipActive) return;
    setActiveLabel(actLabel);
    handleDisplayTooltipContent(actLabel);
  }

  const getSpends = (selectedDay) => {
    let actualSpend = 0;
    if (!selectedDay.hasOwnProperty('current')) {
      const lastActualSpend = data.findLast(d => d.hasOwnProperty('current'));
      if (lastActualSpend) actualSpend = lastActualSpend.current;
    } else {
      actualSpend = selectedDay.current;
    }
    return {
      actualSpend,
      expectedSpend: objectHelpers.getObjProp(selectedDay, 'expected', 0)
    }
  }

  const handleDisplayTooltipContent = (name) => {
    let emptyText = '';
    const index = data.findIndex(d => d.name === name);
    if (index >= 0) {
      const currentItem = data.find(d => d.name === name)
      const spends = getSpends(currentItem);
      setTooltipProps({
        ...tooltipProps,
        ...spends,
        emptyText: {actual: emptyText, expected: emptyText},
        loading: false,
      });
    }
  }

  return (
    <StyledSubscriptionChart
      direction='vertical'
      size='small'
    >
      <ResponsiveContainer
        height={330}
        width='100%'
      >
        <LineChart
          data={data}
          height={200}
          margin={lineChartMargin}
          onMouseMove={handleOnMouseMove}
          width={500}
        >
          <CartesianGrid
            strokeDasharray='5 5'
            strokeOpacity={1}
            strokeWidth={0.5}
            vertical={false}
          />
          <YAxis
            {...axisProps}
            orientation='left'
            padding={{top: 20, bottom: 0}}
            tick={<CustomizedAxisTick getPriceValue={getAmountWithCurrencyCode}/>}
            tickCount={9}
            tickSize={12}
          />
          <XAxis
            {...axisProps}
            dataKey='name'
            fontSize={14}
            padding={{left: 10, right: 10}}
            tickSize={8}
          />
          <Line
            {...lineProps}
            dataKey='expected'
            fill={expectedColor}
            stroke={expectedColor}
            strokeDasharray='5 5'
            dot={(props) => (
              <CustomizedDot
                fill={expectedColor}
                propName='expected'
                {...props}
              />
            )}
          />
          <Line
            {...lineProps}
            dataKey='current'
            fill={actualColor}
            stroke={actualColor}
            dot={(props) => (
              <CustomizedDot
                fill={actualColor}
                isActive={checkIsActiveDot(props)}
                propName='current'
                {...props}
              />
            )}
          />
          <Tooltip
            animationEasing='ease'
            content={<SubscriptionTooltip {...tooltipProps} />}
            cursor={{stroke: '#000', opacity: 0.6}}
            offset={0}
          />
        </LineChart>
      </ResponsiveContainer>
    </StyledSubscriptionChart>
  );
}

SubscriptionChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    expected: PropTypes.number.isRequired,
    current: PropTypes.number
  })),
  tooltipServices: PropTypes.array
}

SubscriptionChart.defaultProps = {
  data: [],
  tooltipServices: []
}

export default SubscriptionChart;
