import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Form, Input, Select} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Collapse} from 'react-collapse';
import {
  StyledCompanyInformationForm,
  StyledCompanyInformationFormAlert
} from './StyledCompanyInformationForm';
import {StyledKYCModalContentStepTitle} from '../../../../KYCModal/StyledKYCModal';
import SpinSmall from '../../../../../SpinSmall';
import EditableAvatar from '../../stepsComponents/EditableAvatar';
import Upload from '../../stepsComponents/Upload';
import AntdCountriesSelect from '../../../../../Select/AntdCountriesSelect';
import {companyActions, mainActions, userActions} from '../../../../../../state/actions';
import {useLocation} from '../../../../../../hooks';
import {
  fileHelpers,
  formHelpers,
  kycHelpers,
  localizationHelpers,
  objectHelpers
} from '../../../../../../utils/helpers';

const {Item} = Form;

const supportedCountries = localizationHelpers.getSupportedCountries();

const countrySelectProps = kycHelpers.getCountrySelectProps();

const initialFormValues = {
  country: undefined,
  currency: 'EUR',
  email: '',
  logo: undefined,
  name: ''
}

const checkIfSupportedCountry = (country) => {
  let supported = false;
  if (country) {
    const countryObj = supportedCountries.find(c => c.name === country);
    if (countryObj) supported = kycHelpers.isSupportedCountry(countryObj['ISO3166-1-Alpha-2']);
  } else {
    supported = true;
  }
  return supported;
}

const CompanyInformationForm = React.forwardRef(({
  currencies,
  employeeEmail,
  getCurrencies,
  getCompanies,
  changeCompany,
  companyRegistration,
  onSubmit,
  ...rest
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['main', 'quickStart']);
  const [form] = Form.useForm();
  const [logo, setLogo] = useState(undefined);
  const {name: defaultCountryName} = useLocation();

  const logoRef = useRef(null);

  const country = Form.useWatch('country', form);

  const isSupported = useMemo(() => checkIfSupportedCountry(country), [country]);

  const {
    info,
    label,
    lengthFieldMessage,
    placeholder,
    validationRequiredRules,
    title
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey: 'company'}), [t]);

  const currencyOptions = useMemo(() => currencies && currencies.map(c => ({label: c, value: c})), [currencies]);

  useEffect(() => getCurrencies && getCurrencies(), [getCurrencies]);

  useEffect(() => {
    if (defaultCountryName && !country) {
      form.setFieldValue('country', defaultCountryName);
    }
  }, [defaultCountryName, form]); // eslint-disable-line react-hooks/exhaustive-deps

  const finishLoading = () => setLoading(false);

  const onFinish = (fields) => {
    const data = {
      company_logo: logo ? fileHelpers.cutFileData(logo) : undefined,
      company_name: fields.name,
      country: fields.country,
      currency: fields.currency,
      email: fields.email
    };
    setLoading(true);
    companyRegistration(
      data,
      (response) => {
        const companyId = objectHelpers.getObjProp(response, 'company_id')
        getCompanies(
          null,
          () => {
            changeCompany(companyId);
            finishLoading();
            onSubmit && onSubmit({enabledKyc: isSupported});
            setLogo(null);
            form.setFieldsValue({
              ...initialFormValues,
              country: defaultCountryName
            });
          },
          finishLoading,
        );
      },
      (err) => {
        const errorsData = err.response.data || {};
        const fields = formHelpers.getFormServerErrorFields(errorsData);
        const logoField = formHelpers.getFormLogoErrorField(errorsData, 'company_logo');
        fields.push(logoField);
        form.setFields(fields);
        finishLoading();
      }
    );
  }

  const handleChangeLogo = async (files) => {
    const file = await fileHelpers.getBase64(files[0].originFileObj);
    setLogo(file);
    form.setFields([{name: 'company_logo', errors: []}]);
  }

  const onAvatarEditClick = () => {
    if (logoRef.current) {
      let uploadButton = logoRef.current.querySelector('.ant-upload-select [role="button"]');
      uploadButton && uploadButton.click();
    }
  }

  return (
    <StyledCompanyInformationForm
      direction='vertical'
      size={32}
      {...rest}
    >
      <StyledKYCModalContentStepTitle>
        {title}
      </StyledKYCModalContentStepTitle>
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          form={form}
          layout='vertical'
          ref={ref}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Item
            label={label('logo')}
            name='company_logo'
          >
            {logo && (
              <EditableAvatar
                onEdit={onAvatarEditClick}
                value={logo}
              />
            )}
            <Upload
              className={logo && 'd-none'}
              imageCrop={true}
              onChange={handleChangeLogo}
              ref={logoRef}
            />
          </Item>
          <Item
            label={label('name')}
            name='name'
            rules={[
              ...validationRequiredRules,
              {min: 2, message: lengthFieldMessage(2, 'min')}
            ]}
          >
            <Input
              minLength={2}
              maxLength={50}
              placeholder={placeholder('name')}
              size='large'
            />
          </Item>
          <Item
            label={label('email')}
            name='email'
            rules={[
              ...validationRequiredRules,
              () => ({
                validator(_, value) {
                  if (value === employeeEmail) {
                    return Promise.reject(new Error(t('validation.enterDifferentEmailForCompany')));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder={placeholder('email')}
              size='large'
              type='email'
            />
          </Item>
          <Item
            label={label('country')}
            name='country'
            rules={validationRequiredRules}
          >
            <AntdCountriesSelect
              countryPropName='name'
              placeholder={placeholder('country')}
              {...countrySelectProps}
            />
          </Item>
          <Collapse isOpened={!isSupported}>
            <StyledCompanyInformationFormAlert
              align='start'
              size='small'
            >
              <InfoCircleOutlined />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <p>{info('bankingOnlyAvailable')} {info('applyForWithLess')} {t('please')} <a href='#'>{t('continueHere')}</a></p>
            </StyledCompanyInformationFormAlert>
          </Collapse>
          <Item
            label={label('currency')}
            name='currency'
            rules={validationRequiredRules}
          >
            <Select
              options={currencyOptions}
              placeholder={placeholder('currency')}
              size='large'
              showSearch
            />
          </Item>
        </Form>
      </SpinSmall>
    </StyledCompanyInformationForm>
  );
});

CompanyInformationForm.propTypes = {
  onSubmit: PropTypes.func
}

const mapStateToProps = state => {
  const {currencies} = state.main;
  const {employee} = state.user;
  return {
    currencies,
    employeeEmail: employee.email
  }
}

const mapDispatchToProps = {
  getCurrencies: mainActions.getCurrencies,
  getCompanies: userActions.getCompanies,
  changeCompany: userActions.changeCompany,
  companyRegistration: companyActions.companyRegistration
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CompanyInformationForm);
