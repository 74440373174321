import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CreditCard from '../../../../../CreditCard';
import {StyledCardDetails} from './StyledCardDetails';
import AuthenticationWindow from '../../../../CardsPage/AuthenticationWindow';
import CardDetailsModal from '../CardDetailsModal';
import {cardStatusesConstants, SCAActionsConstants} from '../../../../../../constants';
import {bankingActions} from '../../../../../../state/actions';
import {cardsHelpers, scaHelpers} from '../../../../../../utils/helpers';

const {ACTIVE, TERMINATED} = cardStatusesConstants;

const defaultCardData = {
  isCardOwner: false,
  pin: '',
  cvv: '',
  embossedName: '',
  expiration: '',
  number: '',
  status: null,
}

const defaultPaymentDetailsData = {
  image: null,
  open: false,
  startTimer: false,
}

const defaultLimits = {
  limit: 0,
  used: 0
}

const SCA_OPERATION_NAME = SCAActionsConstants.DISPLAY_CARD_DETAILS_ACTION;

const CardDetails = ({
  cardDetails,
  cardErrorMessage,
  cardVariant,
  getUserCardImages,
  employeeId,
  phoneNumber,
  hold,
  error,
  dispatch,
  ...rest
}) => {
  const [cardData, setCardData] = useState(defaultCardData);
  const [limits, setLimits] = useState(defaultLimits);
  const [cardDetailsModalProps, setCardDetailsModalProps] = useState(defaultPaymentDetailsData);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});

  const {status} = cardData;
  const active = ACTIVE === status;
  const terminated = TERMINATED === status;
  const noId = cardDetails?.has_no_id || false;

  useEffect(() => {
    if (cardDetails) {
      const {is_live: isLive, user_id: userId, has_no_id: noId} = cardDetails;
      const {value: totalLimit} = cardsHelpers.getCardLimit(cardDetails);
      const totalPaymentValue = cardsHelpers.getCardTotalPaymentValue(cardDetails);

      setCardData({
        ...cardData,
        expiration: cardDetails.expiry_date || '',
        number: cardDetails.masked_pan || '',
        embossedName: cardDetails?.embossed_name || '',
        isCardOwner: employeeId === userId,
        status: cardsHelpers.getStatus(cardDetails.status_code, isLive, noId)
      });
      setLimits({
        ...limits,
        total: totalLimit,
        used: totalPaymentValue,
      });
    }
  }, [cardDetails, employeeId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCardDetails = () => {
    const {id: cardId, user_id: userId} = cardDetails;

    getUserCardImages({
      headers: scaHelpers.getAuthHeaders(SCA_OPERATION_NAME),
      userId,
      cardId,
      successCallback: (images) => {
        let image = null;
        if (images.length > 0) {
          image = `data:image/png;base64,${images[0].file}`;
        }
        setCardDetailsModalProps({
          ...cardDetailsModalProps,
          image,
          loading: false,
          open: true,
          startTimer: true
        });
        handleCloseAuthModal();
      },
      errorCallback: (response) => {
        scaHelpers.SCAResponseCallback({
          response,
          scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps})
        });
      }
    });
  }

  const onSuccessAuth = () => {
    setCardDetailsModalProps({...cardDetailsModalProps, loading: true, open: true, startTimer: false});
    handleCloseAuthModal();
    loadCardDetails();
  }

  const handleCloseAuthModal = () => setAuthWindowProps({...authWindowProps, open: false});

  const handleCloseCardDetailsModal = () => setCardDetailsModalProps({...cardDetailsModalProps, image: null, open: false});

  const handleCancelAuth = () => {
    handleCloseCardDetailsModal();
    handleCloseAuthModal();
  }

  const onMoreClick = () => {
    setCardDetailsModalProps({...cardDetailsModalProps, open: true});
    loadCardDetails();
  }

  const checkIsEnabledShowMore = () => {
    let enabled = false;
    if (cardData.isCardOwner) {
      enabled = true;
    } else if (status === ACTIVE) {
      enabled = !active;
    }
    return enabled;
  }

  const enabledShowMore = checkIsEnabledShowMore();

  return (
    <StyledCardDetails {...rest}>
      <CreditCard
        data={cardData}
        error={error}
        errorMessage={cardErrorMessage}
        limits={limits}
        hold={hold}
        noId={noId}
        onMoreClick={onMoreClick}
        showMore={enabledShowMore}
        terminated={terminated}
        variant={cardVariant}
      />

      <AuthenticationWindow
        {...authWindowProps}
        handleCancel={handleCancelAuth}
        onSuccess={onSuccessAuth}
        operationName={SCA_OPERATION_NAME}
      />

      <CardDetailsModal
        {...cardDetailsModalProps}
        handleCancel={handleCloseCardDetailsModal}
      />
    </StyledCardDetails>
  );
}

CardDetails.propTypes = {
  cardDetails: PropTypes.shape({
    expiry_date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    has_no_id: PropTypes.bool,
    is_live: PropTypes.bool,
    limit_payment_month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    masked_pan: PropTypes.string,
    status_code: PropTypes.number,
    total_payment_month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    user_id: PropTypes.string,
  }),
  cardErrorMessage: PropTypes.any,
  cardVariant: PropTypes.oneOf(['light', 'dark']),
  error: PropTypes.bool,
}

CardDetails.defaultProps = {
  hold: false,
  error: false
}

const mapStateToProps = state => {
  const {id: employeeId} = state.user.employee;
  const {phone_number: phoneNumber} = state.user.user;
  return {
    employeeId,
    phoneNumber
  }
}

const mapDispatchToProps = {
  getUserCardImages: bankingActions.getUserCardImages
}

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);


